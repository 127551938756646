import React from "react";
import { Button } from "@app/components";
import {
  Apple,
  GodotLogoFull,
  Phone,
  Play,
  Smile
} from "@app/components/Icons";

import { Typing } from "./Typing";

const Home = () => {
  return (
    <main>
      <section className="hero">
        <div className="hero_left">
          <div className="hero_pre">
            <Play />
            <Phone />
            <Smile />
          </div>
          <h1 className="hero_h1">App Store ready Godot games in minutes.</h1>
          <p className="hero_p">
            Focus on building great games while we take care of getting them
            shipped to the App Store.{" "}
          </p>
          <div className="hero_buttons grid grid--2col">
            <Button
              size="md"
              className="_mt10"
              variant="secondary"
              href="/docs"
            >
              Read the Docs
            </Button>

            <Button
              size="md"
              className="_mt10"
              variant="primary"
              href="https://npmjs.com/package/shipthis"
            >
              Get ShipThis
            </Button>
          </div>
        </div>
        <div className="hero_right">
          <Typing />
        </div>
      </section>

      <section className="features grid grid--2col">
        <div>
          <h2>Cloud build for iOS without Xcode</h2>
          <p>
            ShipThis manages your{" "}
            <span className="emp-red">iOS certificates</span>,{" "}
            <span className="emp-red">provisioning profiles</span>, and{" "}
            <span className="emp-red">API keys</span> for you. <br />
            <br />
            <span className="emp-white">
              No need to install Xcode or mess with the Apple Developer Portal.
            </span>
          </p>
          <p>
            With one command{" "}
            <span
              className="terminal emp-white"
              style={{ padding: `3px 6px`, borderRadius: 5 }}
            >
              shipthis game ship
            </span>{" "}
            your game will be built on our servers and uploaded to the App
            Store.
          </p>
          <p>
            Your code always remains yours - ShipThis simply handles the heavy
            lifting of getting the end-result to the App Store.
          </p>
        </div>
        <div className="featureList-wrap">
          <h2>Features</h2>
          <ul className="featureList">
            <li>Guided Setup</li>
            <li>Build with one command</li>
            <li>Credentials are managed for you</li>
            <li>Monitor builds in the dashboard</li>
            <li>View error logs and build history</li>
            <li>Integrates with existing CI</li>
          </ul>
          <div className="_flex _flex-center _mt10">
            <Apple fill="white" className="_mr10" width={35} height={35} />
            <GodotLogoFull height={38} width={100} />
          </div>
        </div>
      </section>
    </main>
  );
};

export { Home };
