import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { useDelayedState } from "@app/utils/hooks";

interface Props {
  onPress: () => any;
  isOpen: boolean;
}

const Overlay = ({ onPress, isOpen }: Props) => {
  const isOpenDelayed = useDelayedState(isOpen);

  const styles = useSpring({
    opacity: isOpen ? 1 : 0,
    config: {
      tension: 250
    }
  });

  if (!isOpenDelayed) return null;

  return (
    <animated.a onClick={onPress} className="admin-overlay" style={styles} />
  );
};

export { Overlay };
