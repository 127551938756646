import { ProjectDetails } from "@app/types/api";
import { SVGProps } from "react";
import { GodotLogoMask } from "../Godot";

interface Props extends SVGProps<SVGSVGElement> {
  type: ProjectDetails["gameEngine"];
  size?: number;
}

const GameEngine = ({ type, size = 24, ...props }: Props) => {
  const Component = { godot: GodotLogoMask }[type];
  return <Component {...props} width={size} height={size} />;
};

export { GameEngine };
