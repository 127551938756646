import React, { useRef } from "react";
import classNames from "classnames";
import useMeasure from "react-use-measure";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";

import { showFeedback } from "@app/utils/emitters";
import { useCopyToClipboard, useDownloadBuild } from "@app/utils/hooks";
import { Button, Download, Expand, Link, Log } from "@app/components";
import { Job, JobStatus, Project } from "@app/types";

interface Props {
  project: Project;
  job: Job;
  isActive: boolean;
  isSubscribed: boolean;
}

const JobContent = ({ project, job, isActive, isSubscribed }: Props) => {
  const navigate = useNavigate();
  const fullScreenUrl = `/games/${project.id}/job/${job.id}/full`;

  const logRef = useRef<HTMLElement>(); // Log content for saving
  const { copyText, isCopying, isCopied } = useCopyToClipboard();

  const [ref, { height }] = useMeasure();
  const aniProps = useSpring({
    from: { height: 0 },
    to: { height: isActive ? height : 0 },
  });

  const { downloadBuild, isLoading: isDownloadingBuild } = useDownloadBuild();

  const handleDownloadBuildPress = () => {
    downloadBuild({ buildId: job.build.id, projectId: project.id });
  };

  const handleDownloadLogPress = () => {
    // Currently only download the part that has actually loaded
    // TODO: load all of the pages of the logfile first
    // also, display a loader
    const fileData = logRef.current?.innerText;
    const fileDataReversed = fileData.split("\n").reverse().join("\n");
    const blob = new Blob([fileDataReversed], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `log-${job.id?.substring(0, 5)}.txt`;
    link.href = url;
    link.click();
  };

  // Only show the "?" if the job failed
  const isFailed = job.status === JobStatus.FAILED;
  // Only show the download button if the job is completed
  const isCompleted = job.status === JobStatus.COMPLETED;

  return (
    <animated.div
      style={aniProps}
      className={classNames("job-content", {
        "job-content--visible": isActive,
      })}
    >
      <div ref={ref}>
        <Log
          projectId={project.id}
          jobId={job.id}
          logRef={logRef}
          isSubscribed={isSubscribed}
        />

        <footer className="job-footer">
          <Button
            variant="dark"
            href={fullScreenUrl}
            onClick={() => navigate(fullScreenUrl)}
          >
            <span className="_mr5">View fullscreen</span>
            <Expand width={14} stroke="#95a0aa" />
          </Button>
          <Button
            onClick={handleDownloadLogPress}
            variant="dark"
            centeredLoader
          >
            <span className="_mr5">Download Log</span>
            <Download width={16} stroke="#95a0aa" />
          </Button>
          <Button
            onClick={() => copyText(window.location.host + fullScreenUrl)}
            variant="dark"
            isLoading={isCopying}
            centeredLoader
          >
            {isCopied && "Copied 👍"}
            {!isCopied && (
              <>
                <span className="_mr5">Link</span>
                <Link width={14} stroke="#95a0aa" />
              </>
            )}
          </Button>

          <div />

          {isFailed && (
            <Button onClick={showFeedback} variant="dark">
              ?
            </Button>
          )}
          {isCompleted && (
            <Button
              onClick={handleDownloadBuildPress}
              variant="dark"
              isLoading={isDownloadingBuild}
              centeredLoader
            >
              <Download width={16} stroke="#95a0aa" />
            </Button>
          )}
        </footer>
      </div>
    </animated.div>
  );
};

export { JobContent };
