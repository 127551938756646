import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LogOut, PopOver } from "@app/components";
import { cacheKeys } from "@app/constants";
import { setCurrentJWT, queryClient } from "@app/utils/query";
import { useClickAway } from "@app/utils/hooks/useClickAway";

interface Props {
  showPopOver: boolean;
  toggle?: () => void;
}

const useAuthPopOver = () => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  return { show, toggle };
};

const AuthPopOver = ({ showPopOver, toggle }: Props) => {
  const navigate = useNavigate();
  const ref = useClickAway(toggle);

  const handleLogout = () => {
    setCurrentJWT(null);
    queryClient.invalidateQueries({ queryKey: cacheKeys.isSignedIn });
    queryClient.invalidateQueries({ queryKey: cacheKeys.self });
    // TODO: HOW DO WE HANDLE THIS?
    // queryClient.invalidateQueries({ queryKey: cacheKeys.projects(0) });
    // queryClient.invalidateQueries({ queryKey: cacheKeys.projects(1) });
    // queryClient.invalidateQueries({ queryKey: cacheKeys.projects(2) });
    navigate("/");
  };

  return (
    <PopOver
      clickAwayRef={ref}
      isShowing={showPopOver}
      className="popOver--auth"
    >
      <ul className="popOver-list">
        <li className="popOver-listItem">
          <Link to="/" onClick={handleLogout} className="popOver-link">
            <LogOut width={18} height={18} className="popOver-icon" />
            Logout
          </Link>
        </li>
      </ul>
    </PopOver>
  );
};

export { AuthPopOver, useAuthPopOver };
