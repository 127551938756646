import React from "react";
import { GameEngine as GameEngineType } from "@app/types";
import {
  Section,
  FormControl,
  Dropdown,
  TextInput,
  Gamepad
} from "@app/components";

const GameEngine = ({ formik }) => {
  return (
    <Section>
      <div className="_mb10">
        <div className="gameOverview-header">
          <div className="_flex _flex-center">
            <Gamepad
              width={20}
              height={20}
              strokeWidth={1.5}
              className="gameOverview-headerIcon"
            />
            <div>Game Engine</div>
          </div>
        </div>
        <div className="font--sm">
          ShipThis automatically detects the game engine and version from your
          code. It is not a good idea to change these values manually.
        </div>
      </div>
      <div className="gameOverview-hStack">
        <FormControl label="Game Engine">
          <Dropdown
            title="Game Engine"
            options={Object.values(GameEngineType)}
            value={String(formik.values.details.gameEngine)}
            onChange={(v) => formik.setFieldValue("details.gameEngine", v)}
            onSubmit={formik.handleSubmit}
            isProtected
          />
        </FormControl>
        <FormControl label="Version">
          <TextInput
            title="Game Engine Version"
            placeholder="4.3"
            value={String(formik.values.details.gameEngineVersion)}
            onChange={(v) =>
              formik.setFieldValue("details.gameEngineVersion", v)
            }
            onSubmit={formik.handleSubmit}
            // isLoading={isDirty("details.gameEngineVersion")}
            isProtected
          />
        </FormControl>
      </div>
    </Section>
  );
};

export { GameEngine };
