import React, { useEffect } from "react";

export const useKeyDown = (key: string, fn: () => void) => {
  const keyDown = (event) => {
    if (event.key === key) fn();
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDown);
    return () => {
      window.removeEventListener("keydown", keyDown);
    };
  }, []);
};
