import React, { useRef, useState } from "react";
import classNames from "classnames";

import { Locked } from "@app/components/Icons";
import { UnlockPopup, Spinner } from "@app/components";

interface Props extends React.ComponentProps<"div"> {
  title?: string;
  options: string[];
  value: string;
  // capitalize?: boolean;
  isLoading?: boolean;
  isProtected?: boolean;
  onChange: (e) => void;
  onSubmit?: (e) => void;
}

const Dropdown = ({
  title,
  options,
  value,
  // capitalize,
  isLoading,
  onChange,
  onSubmit,
  isProtected,
  className
}: Props) => {
  const originalValue = useRef(value);
  const inputRef = useRef<HTMLSelectElement>();
  const [isLocked, setIsLocked] = useState(isProtected);
  const [showUnlockPopup, setShowUnlockPopup] = useState(false);

  const undo_keepPreviousValue = () => {
    originalValue.current = inputRef.current.value;
  };

  const undo_recallPreviousValue = () => {
    onChange(originalValue.current);
  };

  const handleUnlock = () => {
    if (isProtected && isLocked && !isLoading) setShowUnlockPopup(true);
  };

  const unlock = async () => {
    await setIsLocked(false);
    await setShowUnlockPopup(false);
    inputRef.current.focus();
  };

  const handleSubmit = (e) => {
    onSubmit(e); // Mutate
    undo_keepPreviousValue();
    setIsLocked(true);
    e.preventDefault();
  };

  const handleCancel = (e) => {
    undo_recallPreviousValue();
    setIsLocked(true);
    e.preventDefault();
  };

  return (
    <>
      <UnlockPopup
        title={title}
        open={showUnlockPopup}
        onClose={() => setShowUnlockPopup(false)}
        unlock={unlock}
      />
      <div
        onClick={handleUnlock}
        className={classNames(
          "formItem-inputContainer",
          "formItem-inputContainer--dropdown",
          className,
          {
            "formItem-inputContainer--locked": isProtected && isLocked
          }
        )}
      >
        <select
          name="cars"
          id="cars"
          ref={inputRef}
          className="formItem-select"
          onBlur={(e) => {
            onChange(e.target.value);
            handleSubmit(e);
          }}
          onChange={(e) => {
            onChange(e.target.value);
            handleSubmit(e);
          }}
          disabled={isLocked}
          value={value}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <div className="formItem-lock formItem-lock--dropdown">
          {isLoading && <Spinner />}
          {isProtected && isLocked && !isLoading && (
            <Locked
              isLocked={isLocked}
              size={12}
              stroke="#adb6c8"
              style={{
                position: "relative",
                top: 2
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export { Dropdown };
