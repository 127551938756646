import React, { useEffect, useState } from "react";

export const useDelayedState = (
  state: boolean,
  delayGoingToTrue: number = 0,
  delayGoingToFalse: number = 250
) => {
  const [delayedState, setDelayedState] = useState<boolean>(state);

  useEffect(() => {
    setTimeout(
      () => {
        setDelayedState(state);
      },
      state ? delayGoingToTrue : delayGoingToFalse
    );
  }, [state]);

  return delayedState;
};
