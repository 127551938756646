import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { EditableProject } from "@app/types";
import { API_URL, cacheKeys } from "@app/constants";
import { queryClient, getAuthHeaders } from "@app/utils/query";

export const useProjectMutation = (projectId: string) =>
  useMutation({
    mutationFn: (data: EditableProject) => {
      const headers = getAuthHeaders();
      const url = `${API_URL}/projects/${projectId}`;
      return axios.put(url, data, { headers });
    },
    onError: (error) => {
      console.log("An useProjectMutation error happened!", error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cacheKeys.project(projectId) });
    }
  });
