import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Certificate = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    stroke="black"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z" />
    <path d="M9 12L11 14L15 10" />
  </svg>
);

export { Certificate };
