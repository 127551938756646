import { JobDetails, Platform } from "@app/types";

/**
 * Works the same way that git short commits are generated.
 * Used for most uuids on the backend where the short value should be unique
 * within one users account.
 */
export function getShortUUID(originalUuid: string, length: number = 8): string {
  return originalUuid.slice(0, length);
}

/**
 * Converts a platform to a human-readable name.
 */
export function getPlatformName(platform: Platform): string {
  switch (platform) {
    case Platform.IOS:
      return "iOS";
    case Platform.ANDROID:
      return "Android";
    default:
      throw new Error(`Unknown platform: ${platform}`);
  }
}

/**
 * Gives us a short string to represent the job Git info
 */
export function getGitInfo(jobDetails: JobDetails): string {
  const gitCommit = jobDetails?.gitCommitHash
    ? getShortUUID(jobDetails?.gitCommitHash, 5)
    : "N/A";
  const gitBranch = jobDetails?.gitBranch || "N/A";
  return `${gitCommit} (${gitBranch})`;
}

/**
 * SemVer and build number in one string
 */
export function getVersionInfo(jobDetails: JobDetails): string {
  const semanticVersion = jobDetails?.semanticVersion || "N/A";
  const buildNumber = jobDetails?.buildNumber || "N/A";
  return `v${semanticVersion} (${buildNumber})`;
}
