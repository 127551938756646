import { SVGProps } from "react";
import { Lock, Unlocked } from "@app/components/Icons";

interface Props extends SVGProps<SVGSVGElement> {
  isLocked: boolean;
  size?: number;
}

const Locked = ({ isLocked, size = 24, ...props }: Props) => {
  const Component = { T: Lock, F: Unlocked }[isLocked ? "T" : "F"];
  return <Component {...props} width={size} height={size} />;
};

export { Locked };
