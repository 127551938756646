import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Play = (props: Props) => (
  <svg
    width={27}
    height={27}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3285 5.64216V21.3578M5.61279 4.51961L16.8383 13.5L5.61279 22.4804V4.51961Z"
      stroke="white"
      strokeWidth={2.2451}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { Play };
