import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Smile = (props: Props) => (
  <svg
    width={28}
    height={27}
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.05609 15.7451C9.05609 15.7451 10.7399 17.9902 13.5463 17.9902C16.3527 17.9902 18.0365 15.7451 18.0365 15.7451M10.1786 10.1324H10.1899M16.9139 10.1324H16.9252M24.7718 13.5C24.7718 19.6997 19.746 24.7255 13.5463 24.7255C7.34662 24.7255 2.3208 19.6997 2.3208 13.5C2.3208 7.30033 7.34662 2.27451 13.5463 2.27451C19.746 2.27451 24.7718 7.30033 24.7718 13.5Z"
      stroke="white"
      strokeWidth={2.2451}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { Smile };
