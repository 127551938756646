import React, { useEffect, useRef } from "react";
import { animated, useSpring } from "@react-spring/web";
import { useIsMobile, useKeyDown } from "@app/utils/hooks";

interface Props extends React.ComponentProps<"div"> {
  open: boolean;
  onClose: () => void;
}

const Content = ({ open, onClose, children }: Props) => {
  const isMobile = useIsMobile(768);
  const nothing = useKeyDown("Escape", onClose);

  const [animatedValues, api] = useSpring(
    () => ({
      from: { translateY: isMobile ? 200 : -20, opacity: 0 },
      to: { translateY: 0, opacity: 1 },
      reverse: !open,
      config: {
        friction: 30,
        tension: isMobile ? 250 : 350
      }
    }),
    [open]
  );

  const opacity = animatedValues.translateY.to(
    [isMobile ? 200 : -20, 0],
    [0, 1]
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  return (
    <>
      <animated.div
        onClick={onClose}
        style={{ opacity }}
        className="popup-backdrop"
      />
      <animated.div
        style={animatedValues}
        onClick={(e) => e.stopPropagation()}
        className="popup-content"
      >
        {children}
      </animated.div>
    </>
  );
};

export { Content };
