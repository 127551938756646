import { useSpring } from "@react-spring/web";
import { useEffect } from "react";

export const useFeedbackAnimation = (
  isOpen: boolean,
  width,
  height,
  page,
  isFinished
) => {
  const [contentStyles, api] = useSpring(
    () => ({
      width: isOpen ? width : 0,
      height: isOpen ? height : 0,
      opacity: isOpen ? 1 : 0,
      config: {
        friction: isOpen ? 24 : 18,
        tension: 275
      }
    }),
    [isOpen, width, height]
  );

  const [pageStyles] = useSpring(
    () => ({
      transform: `translateX(${-page * width}px)`,
      delay: 250,
      config: {
        friction: 20
      }
    }),
    [page]
  );

  const [submitButtonStyles] = useSpring(
    () => ({
      from: { transform: `translateX(0px)` },
      to: [{ transform: `translateX(20px)` }, { transform: `translateX(0px)` }],
      config: {
        duration: 150
      }
    }),
    [page]
  );

  const sectionStyles = {
    minWidth: contentStyles.opacity.to([0, 1], [60, 0]),
    minHeight: contentStyles.opacity.to([0, 1], [60, 0]),
    right: contentStyles.opacity.to([0, 1], [25, 30]),
    bottom: contentStyles.opacity.to([0, 1], [25, 30])
  };

  const buttonStyles = {
    right: contentStyles.opacity.to([0, 1], [6, 16]),
    bottom: contentStyles.opacity.to([0, 1], [6, 16]),
    opacity: contentStyles.opacity.to([0, 1], [1, 0])
  };

  useEffect(() => {
    if (!isFinished) return;
    api.start({
      height: 80,
      delay: 250,
      config: {
        friction: 24
      }
    });
  }, [isFinished]);

  return {
    contentStyles,
    sectionStyles,
    buttonStyles,
    pageStyles,
    submitButtonStyles
  };
};
