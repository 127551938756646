import { useEffect } from "react";
import { useSpring } from "@react-spring/web";
import { STAGES } from "./index";

export const useTimelineAnimation = (lastStage) => {
  // A Manual stagger or trail animation using setTimeout
  const [v1, u1] = useSpring(() => ({ opacity: 0, height: 0 }));
  const [v2, u2] = useSpring(() => ({ opacity: 0, height: 0 }));
  const [v3, u3] = useSpring(() => ({ opacity: 0, height: 0 }));
  const [v4, u4] = useSpring(() => ({ opacity: 0, height: 0 }));
  const [v5, u5] = useSpring(() => ({ opacity: 0, height: 0 }));

  const springValues = [v1, v2, v3, v4, v5];
  const springUpdates = [u1, u2, u3, u4, u5];

  useEffect(() => {
    if (!lastStage) return;
    const lastIndex = STAGES.indexOf(lastStage);
    springUpdates.map((s, i) => {
      setTimeout(() => {
        s.start({
          opacity: 1,
          height: lastIndex === i ? 20 : 6,
          config: {
            friction: 14
          }
        });
      }, i * 150);
    });
  }, [lastStage]);

  return {
    springValues
  };
};
