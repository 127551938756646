import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Android = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path d="M4.20834 8.33261C3.45026 8.33261 2.83334 8.94953 2.83334 9.70761V16.1243C2.83334 16.8823 3.45026 17.4993 4.20834 17.4993C4.96642 17.4993 5.58334 16.8823 5.58334 16.1243V9.70761C5.58334 8.94953 4.96642 8.33261 4.20834 8.33261Z" />
      <path d="M19.7916 8.33261C19.0335 8.33261 18.4166 8.94953 18.4166 9.70761V16.1243C18.4166 16.8823 19.0335 17.4993 19.7916 17.4993C20.5497 17.4993 21.1666 16.8823 21.1666 16.1243V9.70761C21.1666 8.94953 20.5497 8.33261 19.7916 8.33261Z" />
      <path d="M17.0416 8.33261H6.9583C6.7053 8.33261 6.49997 8.53794 6.49997 8.79094V16.1243C6.49997 17.2316 7.2883 18.1565 8.3333 18.3701V21.6242C8.3333 22.3823 8.95021 22.9992 9.70829 22.9992C10.4664 22.9992 11.0833 22.3823 11.0833 21.6242V18.4159H12.9166V21.6242C12.9166 22.3823 13.5335 22.9992 14.2916 22.9992C15.0497 22.9992 15.6666 22.3823 15.6666 21.6242V18.3701C16.7116 18.1574 17.4999 17.2316 17.4999 16.1243V8.79094C17.4999 8.53794 17.2946 8.33261 17.0416 8.33261Z" />
    </g>
    <path d="M15.332 2.89864L16.4485 1.78214C16.6273 1.60339 16.6273 1.31281 16.4485 1.13406C16.2698 0.955313 15.9792 0.955313 15.8004 1.13406L14.4988 2.43573C13.7563 2.10389 12.912 1.91598 12 1.91598C11.0879 1.91598 10.2436 2.10389 9.50113 2.43573L8.19946 1.13406C8.02072 0.955313 7.73013 0.955313 7.55138 1.13406C7.37263 1.31281 7.37263 1.60339 7.55138 1.78214L8.66788 2.89864C7.33597 3.80064 6.49997 5.25263 6.49997 6.95763C6.49997 7.21063 6.7053 7.41596 6.9583 7.41596H17.0416C17.2946 7.41596 17.4999 7.21063 17.4999 6.95763C17.4999 5.25263 16.6639 3.80064 15.332 2.89864ZM9.70829 5.58263C9.45529 5.58263 9.24996 5.3773 9.24996 5.1243C9.24996 4.8713 9.45529 4.66597 9.70829 4.66597C9.96129 4.66597 10.1666 4.8713 10.1666 5.1243C10.1666 5.3773 9.96129 5.58263 9.70829 5.58263ZM14.2916 5.58263C14.0386 5.58263 13.8333 5.3773 13.8333 5.1243C13.8333 4.8713 14.0386 4.66597 14.2916 4.66597C14.5446 4.66597 14.7499 4.8713 14.7499 5.1243C14.7499 5.3773 14.5446 5.58263 14.2916 5.58263Z" />
  </svg>
);

export { Android };
