import React from "react";
import GitInfo from "react-git-info/macro";
import { DateTime } from "luxon";
import { NavLink } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";

import { Logo } from "@app/components";
import { useIsMobile, useLockBody } from "@app/utils/hooks";
import { Dashboard, FileKey, Gamepad, Git } from "@app/components/Icons";
import { hideSidebar, useSidebarState } from "@app/utils/emitters";

import { Overlay } from "./Overlay";

const Sidebar = () => {
  const isMobile = useIsMobile(1024);
  const { isShowing } = useSidebarState();

  const gitInfo = GitInfo();
  const commitDate = DateTime.fromISO(gitInfo.commit.date);
  const formattedDate = DateTime.fromISO(commitDate).toRelative();

  useLockBody(isShowing && isMobile, !isShowing || !isMobile, [
    isShowing,
    isMobile
  ]);

  const asideStyles = useSpring({
    left: isShowing ? 0 : -200,
    config: {
      tension: 250
    }
  });

  const hide = () => {
    if (isMobile) hideSidebar(); // Mobile only, sidebar is always showing on desktop
  };

  return (
    <>
      <Overlay onPress={hide} isOpen={isShowing} />
      <animated.aside className="admin-sidebar" style={asideStyles}>
        <Logo linkTo="/dashboard" className="admin-logo" onClick={hide} />
        <nav className="admin-nav">
          <ul>
            <li className="admin-navItem">
              <NavLink to="/dashboard" className="admin-navLink" onClick={hide}>
                <div className="admin-navIconWrap">
                  <Dashboard width={20} className="admin-navIcon" />
                </div>
                Dashboard
              </NavLink>
            </li>
            <li className="admin-navItem">
              <NavLink
                to="/credentials"
                className="admin-navLink"
                onClick={hide}
              >
                <div className="admin-navIconWrap">
                  <FileKey width={18} className="admin-navIcon" />
                </div>
                Credentials
              </NavLink>
            </li>
            <li className="admin-navItem">
              <NavLink to="/games" className="admin-navLink" onClick={hide}>
                <div className="admin-navIconWrap">
                  <Gamepad width={18} className="admin-navIcon" />
                </div>
                Games
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="commit">
          <Git className="commit-icon" />
          <div>
            #{gitInfo.commit.shortHash}
            <br />
            {formattedDate}
          </div>
        </div>
      </animated.aside>
    </>
  );
};

export { Sidebar };
