// The logic here gets the API and WS URLs based on the hostname.
//
// This should work for these scenarios:
// - locally via yarn start in the frontend repo (api.develop.shipthis.cc)
// - via docker-compose (using the same ngrok hostname as the frontend for the API)
// - deployed in development (api.develop.shipthis.cc) and production (api.shipthis.cc)

// NB: window might not be set when building the static docs
const hostname =
  typeof window !== "undefined" ? window.location.hostname : "localhost";

const isPublic = hostname.includes("shipthis.cc");
const apiDomain = (isPublic ? `api.` : "") + hostname; // develop.shipthis.cc -> api.develop.shipthis.cc
const wsDomain = (isPublic ? `ws.` : "") + hostname; // develop.shipthis.cc -> ws.develop.shipthis.cc

// Used for local development
const DEV_API_URL = "https://api.develop.shipthis.cc/api/1.0.0";
const DEV_WS_URL = "wss://ws.develop.shipthis.cc";

const isLocalhost = hostname === "localhost";

export const API_URL = isLocalhost
  ? DEV_API_URL
  : `https://${apiDomain}/api/1.0.0`;

export const WS_URL = isLocalhost ? DEV_WS_URL : `wss://${wsDomain}`;

export const CLI_README_URL =
  "https://shipthis.ams3.digitaloceanspaces.com/public/README.md";
