import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

export const LogOut = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      stroke="#000"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4m7 14 5-5m0 0-5-5m5 5H9" />
    </svg>
  );
};
