import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Tabs } from "@app/components";
import { useProject } from "@app/utils/query";
import { JobLogEntry, Project } from "@app/types";

import { Header } from "../_Shared";
import { GameJobs } from "./Jobs";
import { GameBuilds } from "./Builds";
import { GameOverview } from "./Overview";
import { GameCredentials } from "./Credentials";

enum TABS {
  OVERVIEW = "OVERVIEW",
  JOBS = "JOBS",
  BUILDS = "BUILDS",
  CREDENTIALS = "CREDENTIALS"
}

type Params = {
  projectId: Project["id"];
  jobId?: JobLogEntry["id"];
  tab?: TABS;
};

const Game = () => {
  const { jobId, projectId, tab } = useParams<Params>();
  const { data: project } = useProject(projectId);
  const [selectedTab, setSelectedTab] = useState<boolean | string>(false);

  const loadTab = () => {
    const newTab = jobId ? TABS.JOBS : tab?.toUpperCase() || TABS.OVERVIEW;
    setSelectedTab(newTab);
  };

  useEffect(() => {
    loadTab();
  }, [tab]);

  const TabContent = ({ tab, projectId, jobId }) => (
    <>
      {tab === TABS.OVERVIEW && <GameOverview projectId={projectId} />}
      {tab === TABS.JOBS && <GameJobs projectId={projectId} jobId={jobId} />}
      {tab === TABS.BUILDS && <GameBuilds projectId={projectId} />}
      {tab === TABS.CREDENTIALS && <GameCredentials projectId={projectId} />}
    </>
  );

  return (
    <main className="admin-main">
      <Header title={project?.name} />
      <Tabs tabs={TABS} tab={selectedTab} selectTab={setSelectedTab} />
      <TabContent tab={selectedTab} projectId={projectId} jobId={jobId} />
    </main>
  );
};

export { Game };
