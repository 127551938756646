import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { queryClient, setCurrentJWT } from "@app/utils/query";
import { ExchangeRequest, SelfWithJWT } from "@app/types";
import { castObjectDates } from "@app/utils/dates";

export async function exchangeOTPForSelfWithJWT(
  request: ExchangeRequest
): Promise<SelfWithJWT> {
  try {
    const response = await axios.post(`${API_URL}/auth/exchange`, request);
    return castObjectDates<SelfWithJWT>(response.data);
  } catch (error) {
    console.warn("exchangeOTPForSelf Error", error);
    throw error;
  }
}

export const useExchangeMutation = () => {
  return useMutation({
    mutationFn: exchangeOTPForSelfWithJWT,
    onSuccess: async (data: SelfWithJWT) => {
      const { jwt, ...self } = data;
      setCurrentJWT(jwt);
      queryClient.setQueryData(cacheKeys.isSignedIn, true);
      queryClient.setQueryData(cacheKeys.self, self);
    },
  });
};
