import React, { useState } from "react";
import Typewriter, { TypewriterClass } from "typewriter-effect";
import { Terminal } from "@app/components";

const EXAMPLE_COMMANDS = [
  "npm install -g shipthis",
  "shipthis login",
  "shipthis game wizard --platform ios",
];

function typeCommands(
  writer: TypewriterClass,
  commands: string[],
  lineDelayMs = 1500
): TypewriterClass {
  const prompt = "$";
  let animation = writer;
  for (let i = 0; i < commands.length; i++) {
    const output =
      i == 0 ? `${prompt} ${commands[i]}` : `<br/>${prompt} ${commands[i]}`;
    animation = animation.typeString(output).pauseFor(lineDelayMs);
  }
  return animation;
}

const Typing = () => {
  const [finishedTyping, setFinishedTyping] = useState(false);

  return (
    <>
      <Terminal className="hero_terminal _mb10">
        <Typewriter
          options={{
            delay: 30,
          }}
          onInit={(typewriter) => {
            typeCommands(typewriter, EXAMPLE_COMMANDS)
              .pauseFor(1500)
              .callFunction(() => setFinishedTyping(true))
              .start();
          }}
        />
      </Terminal>
      {finishedTyping && (
        <Terminal className="hero_terminal">
          <Typewriter
            options={{
              delay: 30,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString("$ shipthis game ship")
                .pauseFor(1500)
                .typeString("<br/> ✅ shipped")
                .start();
            }}
          />
        </Terminal>
      )}
    </>
  );
};

export { Typing };
