import React from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "./";
import { Feedback } from "@app/components";

export const LayoutAdmin = () => {
  return (
    <div className="page-wrapper">
      <div className="page page--admin">
        <Sidebar />
        <Outlet />
      </div>
      <Feedback />
    </div>
  );
};
