import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useIsSignedIn } from "@app/utils/query";
import { Avatar, Button, Logo, Navigation } from "@app/components";
import { AuthPopOver, useAuthPopOver } from "@app/components/PopOver/Auth";

const Header = () => {
  const navigate = useNavigate();
  const { data: isSignedIn } = useIsSignedIn();
  const { show: showPopOver, toggle } = useAuthPopOver();

  return (
    <header className="header">
      <Logo className="header_logo" withText withBeta size="sm" />
      <ul className="nav">
        <li className="nav_item">
          <a href="/docs">Docs</a>
        </li>
      </ul>
      <Navigation className="header_mobileNavButton" />
      {isSignedIn ? (
        <div className="_flex">
          <Button
            size="sm"
            variant="secondary"
            className="header_logInButton"
            onClick={() => navigate("/dashboard")}
          >
            My Account
          </Button>
          <Avatar size={40} className="_ml10" onClick={toggle}>
            <AuthPopOver showPopOver={showPopOver} toggle={toggle} />
          </Avatar>
        </div>
      ) : (
        <Button
          size="sm"
          className="header_logInButton"
          onClick={() => navigate("/login")}
        >
          Log in
        </Button>
      )}
    </header>
  );
};

export { Header };
