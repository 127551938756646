import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Message = (props: Props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    stroke="black"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M21.5 15.5C21.5 16.0304 21.2893 16.5391 20.9142 16.9142C20.5391 17.2893 20.0304 17.5 19.5 17.5H7.5L3.5 21.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H19.5C20.0304 3.5 20.5391 3.71071 20.9142 4.08579C21.2893 4.46086 21.5 4.96957 21.5 5.5V15.5Z" />
  </svg>
);

export { Message };
