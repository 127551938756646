import React from "react";

import { Job, JobStatus, Project } from "@app/types";
import {
  Chevron,
  Spinner,
  STATE_COLORS,
  Status,
  Timeline,
  TimeAgo
} from "@app/components";
import {
  getPlatformName,
  getShortUUID,
  getVersionInfo
} from "@app/utils/helpers";

interface Props {
  job: Job;
  project: Project;
  isSubscribed: boolean;
  onSelect: () => void;
}

const JobHeader = ({ onSelect, project, job, isSubscribed }: Props) => {
  const { id, type, status, updatedAt } = job;

  return (
    <div className="job-header" onClick={onSelect}>
      <span className="job-id">
        <Chevron width={18} stroke="white" />
        <span>#{getShortUUID(id, 5)}</span>
      </span>
      <span className="job-nameWrap">
        <span className="job-name">{getVersionInfo(job.details)}</span>
        <span className="job-type">{getPlatformName(type)}</span>
      </span>
      <Timeline
        projectId={project.id}
        jobId={job.id}
        className="job-timeLine"
      />
      <TimeAgo date={updatedAt} showIcon showAgo className="job-updated" />
      <span className="job-status">
        {status === JobStatus.PROCESSING ||
          (isSubscribed && <Spinner className="_mr10" width={12} />)}
        <Status variant={STATE_COLORS[status]}>{status}</Status>
      </span>
    </div>
  );
};

export { JobHeader };
