import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMeasure from "react-use-measure";
import { animated, useSpring } from "@react-spring/web";

import {
  RequestOTPParams,
  SubmitOTPParams,
  useIsSignedInMutation
} from "@app/utils/query";
import { Button, Logo } from "@app/components";
import { getErrorMessage } from "@app/utils/errors";
import { Footer } from "../_Shared";

enum LoginStep {
  ENTER_EMAIL,
  ENTER_OTP
}

export const Login = () => {
  const [step, setStep] = useState<LoginStep>(LoginStep.ENTER_EMAIL);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState<undefined | string>();
  const loginMutation = useIsSignedInMutation();
  const navigate = useNavigate();
  const [ref, { height }] = useMeasure();

  const [animatedValues, api] = useSpring(
    () => ({
      to: { height: height },
      config: {
        tension: 350
      }
    }),
    [height]
  );

  const handleSubmitEmail = async () => {
    try {
      const params: RequestOTPParams = { email };
      await loginMutation.mutateAsync(params);
      setStep(LoginStep.ENTER_OTP);
      setError("");
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  const handleSubmitOTP = async () => {
    try {
      const params: SubmitOTPParams = { email, otp: code };
      const selfWithJWT = await loginMutation.mutateAsync(params);
      setError("");
      // If the user has not accepted the terms, redirect them to the agreement page
      const destination = selfWithJWT.details?.hasAcceptedTerms
        ? "/dashboard"
        : "/agreement";
      navigate(destination);
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  const submit = () => {
    setError(undefined);
    if (step === LoginStep.ENTER_OTP) {
      handleSubmitOTP();
    } else if (step === LoginStep.ENTER_EMAIL) {
      handleSubmitEmail();
    }
  };

  return (
    <main>
      <form
        className="auth"
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <div className="_flex _flex-center _flex-spaceBetween">
          <Logo size="sm" />
          <h1 className="auth_h1">Login</h1>
        </div>
        {error && (
          <animated.div style={animatedValues} className="auth_error_wrap">
            <div ref={ref} className="auth_error">
              {error}
            </div>
          </animated.div>
        )}
        {step === LoginStep.ENTER_EMAIL && (
          <>
            <p className="auth_p">
              We will send you a one-time signin code to your email.
            </p>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="auth_input"
            />
          </>
        )}
        {step === LoginStep.ENTER_OTP && (
          <>
            <p className="auth_p">Please enter the code we sent you.</p>
            <input
              type="number"
              name="number"
              placeholder="Enter code."
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="auth_input"
            />
          </>
        )}

        <Button
          size="md"
          onClick={submit}
          centeredLoader
          isLoading={loginMutation.isPending}
        >
          Login
        </Button>
        <input type="submit" hidden />
      </form>
      <Footer />
    </main>
  );
};
