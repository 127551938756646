import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { Self } from "@app/types";
import { castObjectDates } from "@app/utils/dates";

import { queryClient, getAuthHeaders } from "@app/utils/query";

export async function acceptTerms(): Promise<Self> {
  try {
    const headers = getAuthHeaders();
    const response = await axios.post(
      `${API_URL}/me/acceptTerms`,
      {},
      { headers }
    );
    return castObjectDates<Self>(response.data);
  } catch (error) {
    console.warn("acceptTerms Error", error);
    throw error;
  }
}

// Marks the user as accepting the terms and updates cached self
export const useAcceptTermsMutation = () => {
  return useMutation({
    mutationFn: acceptTerms,
    onSuccess: async (data: Self) => {
      queryClient.setQueryData(cacheKeys.self, data);
    },
  });
};
