import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

export const FileKey = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path d="M4 10V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4" />
        <path d="M14 2v6h6M4 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4m6-8-4.5 4.5M9 11l1 1" />
      </g>
    </svg>
  );
};
