import React from "react";
import { Section, Tag, FormControl, TextInput } from "@app/components";

const GameVersioning = ({ formik }) => {
  return (
    <Section>
      <div className="_mb10">
        <div className="gameOverview-header">
          <div className="_flex _flex-center">
            <Tag width={18} height={18} className="gameOverview-headerIcon" />
            <div>Versioning</div>
          </div>
        </div>
        <div className="font--sm">
          The Build Number will automatically be incremented after a successful
          build. The Semantic Version is manually controlled.
        </div>
      </div>
      <div className="gameOverview-hStack">
        <FormControl label="Semantic Version" hasInfo>
          <TextInput
            title="Semantic Version"
            placeholder="0.1.1"
            value={formik.values.details.semanticVersion}
            // isLoading={isDirty("details.semanticVersion")}
            onChange={(v) => formik.setFieldValue("details.semanticVersion", v)}
            onSubmit={formik.handleSubmit}
            isProtected
          />
        </FormControl>
        <FormControl label="Build Number" clipLabel>
          <TextInput
            title="Build"
            placeholder="1"
            type="number"
            value={String(formik.values.details.buildNumber)}
            // isLoading={isDirty("details.buildNumber")}
            onChange={(v) => formik.setFieldValue("details.buildNumber", v)}
            onSubmit={formik.handleSubmit}
            isProtected
          />
        </FormControl>
      </div>
    </Section>
  );
};

export { GameVersioning };
