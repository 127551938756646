import React from "react";

const Docs = () => {
  return (
    <main>
      <section className="docs">Docs handled by Docusaurus.</section>
    </main>
  );
};

export { Docs };
