import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { castArrayObjectDates } from "@app/utils/dates";
import { Job, JobLogEntry, Project } from "@app/types";

import { getAuthHeaders } from "./useIsSignedIn";

export interface JobLogsQueryParams {
  projectId: Project["id"];
  jobId: Job["id"];
  cursor?: string | unknown;
  pageSize?: number;
  enabled?: boolean;
  keepPreviousData?: boolean;
  select?: (data: any) => any;
}

export async function fetchJobLogs({
  projectId,
  jobId,
  cursor,
  pageSize = 100
}: JobLogsQueryParams): Promise<JobLogEntry[]> {
  try {
    const headers = getAuthHeaders();
    const base = `${API_URL}/projects/${projectId}/jobs/${jobId}/logs/?pageSize=${pageSize}`;
    const url = base + (cursor ? `&cursor=${cursor}` : "");
    const { data: r } = await axios.get(url, { headers });
    // TODO: data.data is because it returns { data: [], nextCursor: "" }
    // We want to implement pagination with keepPreviousData
    // As we are using a cursor pagination, we don't know the total page count
    return castArrayObjectDates<JobLogEntry>(r.data, ["sentAt", "createdAt"]);
  } catch (error) {
    console.warn("fetchJobLogs Error", error);
    throw error;
  }
}

export const useJobLogs = ({
  projectId,
  jobId,
  cursor,
  pageSize,
  ...props
}: JobLogsQueryParams): UseQueryResult<JobLogEntry[]> => {
  return useQuery<JobLogEntry[], AxiosError>({
    queryKey: cacheKeys.jobLogEntries(jobId, cursor, "plain"),
    queryFn: () => fetchJobLogs({ projectId, jobId, cursor, pageSize }),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
    enabled: !!projectId && !!jobId,
    // keepPreviousData: true,
    ...props
  });
};
