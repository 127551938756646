import React from "react";
import classNames from "classnames";
import { useDelayedUnmout } from "@app/utils/hooks";
import { animated, useSpring } from "@react-spring/web";

interface Props extends React.ComponentProps<"div"> {
  isShowing: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  clickAwayRef?: any;
}

const PopOver = ({
  isShowing,
  onClose,
  className,
  children,
  clickAwayRef,
  ...props
}: Props) => {
  const [animatedValues] = useSpring(
    () => ({
      from: { translateY: -10, opacity: 0 },
      to: { translateY: 0, opacity: 1 },
      reverse: !isShowing,
      config: {
        duration: 250
      }
    }),
    [isShowing]
  );

  const delayedUnmount = useDelayedUnmout(isShowing);
  if (!delayedUnmount) return null;

  return (
    <animated.div
      ref={clickAwayRef}
      style={animatedValues}
      className={classNames("popOver", className)}
      {...props}
    >
      {children}
    </animated.div>
  );
};

export { PopOver };
