import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Platform, Project, ProjectPlatformProgress } from "@app/types";
import { API_URL, cacheKeys } from "@app/constants";

import { getAuthHeaders } from "./useIsSignedIn";

export async function fetchProjectProgress(
  projectId: string,
  platform: Platform
): Promise<ProjectPlatformProgress> {
  try {
    const headers = getAuthHeaders();
    const url = `${API_URL}/projects/${projectId}/${platform}/progress`;
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.warn("fetchProjectProgress Error", error);
    throw error;
  }
}

export const useProjectProgress = (
  projectId: string,
  platform: Platform
): UseQueryResult<ProjectPlatformProgress> => {
  return useQuery<ProjectPlatformProgress, AxiosError>({
    queryKey: cacheKeys.projectProgress(projectId, platform),
    queryFn: () => fetchProjectProgress(projectId, platform),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};
