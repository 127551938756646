import mitt from "mitt";
import { useEffect, useState } from "react";

const EVENTS = { SHOW: "SHOW", HIDE: "HIDE" } as const;
const emitter = mitt();

export const showFeedback = () => emitter.emit(EVENTS.SHOW);
export const hideFeedback = () => emitter.emit(EVENTS.HIDE);

export const useFeedbackState = () => {
  const [isShowing, setIsShowing] = useState(false);

  const show = () => setIsShowing(true);
  const hide = () => setIsShowing(false);

  useEffect(() => {
    emitter.on(EVENTS.SHOW, show);
    emitter.on(EVENTS.HIDE, hide);

    return () => {
      emitter.off(EVENTS.SHOW, show);
      emitter.off(EVENTS.HIDE, hide);
    };
  }, []);

  return { isShowing, setIsShowing };
};
