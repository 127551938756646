import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { castArrayObjectDates } from "@app/utils/dates";
import { UserCredential } from "@app/types";

import { getAuthHeaders } from "./useIsSignedIn";

export interface UserCredentialsQueryResult {
  userCredentials: UserCredential[];
  pageCount: number;
}

export async function fetchUserCredentials(
  pageNumber: number
): Promise<UserCredentialsQueryResult> {
  try {
    const headers = getAuthHeaders();
    const url = `${API_URL}/credentials`;
    const { data } = await axios.get(url, { headers, params: { pageNumber } });
    return {
      userCredentials: castArrayObjectDates<UserCredential>(data.data),
      pageCount: data.pageCount
    };
  } catch (error) {
    console.warn("fetchUserCredentials Error", error);
    throw error;
  }
}

export const useUserCredentials = (
  pageNumber: number = 0
): UseQueryResult<UserCredentialsQueryResult> => {
  return useQuery<UserCredentialsQueryResult, AxiosError>({
    queryKey: cacheKeys.userCredentials(pageNumber),
    queryFn: () => fetchUserCredentials(pageNumber),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};
