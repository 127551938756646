import Axios from "axios";

export const __DEV__ =
  typeof process !== "undefined" && process.env.REACT_APP_DEBUG === "true";

export function isAbortError(exception: any) {
  return ["CanceledError", "AbortError"].includes(exception.name);
}

export function isNetworkError(exception: any) {
  if (!Axios.isAxiosError(exception)) return false;
  return ["ECONNABORTED", "ERR_NETWORK"].includes(`${exception.code}`);
}

// Util to extract API error messages if present
export function getErrorMessage(error: any) {
  try {
    if (isNetworkError(error)) {
      return "Please check your internet connection.";
    }

    const data = error?.response?.data;
    // Zod errors from the backend are an array
    const apiValidation = Array.isArray(data)
      ? data
          .map((r) => ("message" in r ? `Error - ${r.message}` : r.toString()))
          .join(" ")
      : "";

    const apiErr = error?.response?.data?.error || "";
    const apiMsg = `${apiErr}${apiValidation ? " " + apiValidation : ""}`;
    if (apiMsg.length === 0) {
      return "message" in error ? error.message : error.toString();
    }
    return apiMsg;
  } catch {
    return error ? error.toString() : "Error";
  }
}

export const trace = (...opt: any[]) => (__DEV__ ? console.log(...opt) : null);

// Builds a trace function
export function makeTrace(category: string, level: any) {
  return function trace(message: any) {
    if (__DEV__) console.log(category, message);
    // TODO: sentry
  };
}
