import React from "react";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";

interface Props<T> extends React.ComponentProps<"div"> {
  tabs: T;
  tab: T;
  selectTab: React.Dispatch<React.SetStateAction<T>>;
}

const Tabs = ({ tab, selectTab, tabs: TABS, ...props }: Props<typeof TABS>) => {
  const { projectId } = useParams();

  return (
    <div className="tabs" {...props}>
      {Object.keys(TABS).map((t) => (
        <Link
          key={t}
          to={`/games/${projectId}/${t.toLocaleLowerCase()}`}
          className={classNames("tab", { "tab--active": tab === t })}
          onClick={() => selectTab(t)}
        >
          {t?.toLowerCase()}
        </Link>
      ))}
    </div>
  );
};

export { Tabs };
