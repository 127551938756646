import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Apple = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20.9267 16.3241C20.8536 16.2103 20.7342 16.1345 20.6001 16.1169C19.0042 15.8976 17.8015 14.5257 17.8015 12.9252C17.8015 11.5217 18.7082 10.2894 20.0579 9.85738C20.1949 9.8139 20.3031 9.70936 20.3521 9.57429C20.4012 9.43922 20.3845 9.28935 20.3068 9.16908C19.1698 7.38263 17.4879 6.44916 16.4138 6.44916C15.5368 6.44916 14.7162 6.72485 13.9909 6.96816C13.3692 7.17725 12.7817 7.3743 12.2507 7.3743C11.7196 7.3743 11.1322 7.17725 10.5105 6.96816C9.78609 6.72485 8.96548 6.44916 8.08752 6.44916C6.36028 6.44916 2.99924 8.89801 2.99924 13.3877C2.99924 17.6545 6.11142 23.1017 8.5501 23.1017C9.84807 23.1017 10.6141 22.7585 11.2302 22.4837C11.6142 22.3117 11.9167 22.1766 12.2507 22.1766C12.5846 22.1766 12.8872 22.3117 13.2711 22.4837C13.8872 22.7585 14.6542 23.1017 15.9512 23.1017C17.8848 23.1017 20.0672 19.7823 20.9812 16.7062C21.0192 16.5776 20.9988 16.4379 20.9267 16.3241Z" />
    <path d="M11.7881 6.44914C14.5941 6.44914 16.8764 4.16682 16.8764 1.36086C16.8764 1.10552 16.6692 0.898285 16.4138 0.898285C13.6079 0.898285 11.3255 3.18061 11.3255 5.98657C11.3255 6.24191 11.5328 6.44914 11.7881 6.44914Z" />
  </svg>
);

export { Apple };
