import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  white?: string;
}

const LogoSymbol2 = ({ white = "white", ...props }: Props) => (
  <svg
    width={55}
    height={31}
    viewBox="0 0 55 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M54.873 1.35009C54.873 0.878847 54.491 0.496826 54.0198 0.496826L36.9544 0.496826C32.242 0.496826 28.4218 4.31703 28.4218 9.0295V9.0295C28.4218 9.50074 28.8038 9.88276 29.275 9.88276L46.3404 9.88276C51.0528 9.88276 54.873 6.06256 54.873 1.35009V1.35009Z"
        fill="#fe8a8b"
      />
      <path
        d="M47.0854 11.5893L47.0854 21.8285C47.0854 26.541 43.2652 30.3612 38.5528 30.3612V30.3612C38.0815 30.3612 37.6995 29.9792 37.6995 29.5079L37.6995 20.9753C37.6995 15.7915 41.9017 11.5893 47.0854 11.5893V11.5893Z"
        fill={white}
      />
      <g>
        <path
          d="M54.6578 15.1876C54.6578 12.7199 52.6572 10.7193 50.1894 10.7193L36.5455 10.7193C32.0586 10.7193 28.4214 14.3567 28.4215 18.8435V18.8435C28.4215 19.2922 28.7852 19.656 29.2339 19.656L50.1896 19.6559C52.6574 19.6559 54.6579 17.6554 54.6578 15.1876V15.1876Z"
          fill={white}
        />
      </g>
    </g>
    <g>
      <path
        d="M0.156738 8.7089C0.156738 9.16244 0.524405 9.53011 0.977946 9.53011L23.2736 9.53011C25.4737 9.53011 27.2572 7.7466 27.2572 5.54654V5.54654C27.2572 2.75766 24.9964 0.496821 22.2075 0.496821L8.36882 0.496821C3.83341 0.496821 0.156738 4.17349 0.156738 8.7089V8.7089Z"
        fill="#ffa55a"
      />
      <path
        d="M27.2573 21.9605C27.2573 21.4746 26.8634 21.0807 26.3775 21.0807L11.5365 21.0807C6.67714 21.0807 2.73785 25.02 2.73785 29.8793V29.8793C2.73785 30.3653 3.13178 30.7592 3.61771 30.7592L18.4587 30.7592C23.318 30.7592 27.2573 26.8199 27.2573 21.9605V21.9605Z"
        fill={white}
      />
      <path
        d="M0.458886 11.2412C0.465412 10.7877 0.838332 10.4253 1.29183 10.4319L19.3571 10.6918C23.8921 10.7571 27.5155 14.4863 27.4502 19.0212V19.0212C27.4437 19.4747 27.0708 19.8371 26.6173 19.8305L8.55195 19.5706C4.01701 19.5053 0.393622 15.7761 0.458886 11.2412V11.2412Z"
        fill={white}
      />
    </g>
  </svg>
);

export { LogoSymbol2 };
