import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { Job } from "@app/types";
import { API_URL, cacheKeys } from "@app/constants";
import { castArrayObjectDates } from "@app/utils/dates";

import { getAuthHeaders } from "./useIsSignedIn";

export async function fetchMyRecentJobs(): Promise<Job[]> {
  try {
    const headers = getAuthHeaders();
    const url = `${API_URL}/me/recentJobs`;
    const { data } = await axios.get(url, { headers });
    return castArrayObjectDates<Job>(data);
  } catch (error) {
    console.warn("fetchMyRecentJobs Error", error);
    throw error;
  }
}

export const useMyRecentJobs = (): UseQueryResult<Job[]> => {
  return useQuery<Job[], AxiosError>({
    queryKey: cacheKeys.myRecentJobs,
    queryFn: () => fetchMyRecentJobs(),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};
