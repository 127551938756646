import React from "react";
import { X } from "@app/components/Icons";

interface Props extends React.ComponentProps<"section"> {}

const CloseButton = ({ onClick }: Props) => (
  <a href="#" className="feedback-closeButton" onClick={onClick}>
    <X />
  </a>
);

export { CloseButton };
