import { SVGProps } from "react";
import { CredentialsType } from "@app/types";
import { Certificate, Key } from "@app/components/Icons";

interface Props extends SVGProps<SVGSVGElement> {
  type: CredentialsType;
  size?: number;
}

const Credential = ({ type, size = 24, ...props }: Props) => {
  const Component = { KEY: Key, CERTIFICATE: Certificate }[type];
  return <Component width={size} height={size} {...props} />;
};

export { Credential };
