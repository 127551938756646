import * as React from "react";

const Navigation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={31}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.765}
      d="M3.75 8h22.5M3.75 15.5h22.5M3.75 23h22.5"
    />
  </svg>
);
export { Navigation };
