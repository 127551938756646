import React from "react";
import classNames from "classnames";
import { animated } from "@react-spring/web";

interface Props extends React.ComponentProps<"section"> {
  isShowing: boolean;
  style: any;
}

const Widget = ({ isShowing, style, children }: Props) => (
  <animated.section
    className={classNames("feedback", { isShowing })}
    style={style}
  >
    {children}
  </animated.section>
);

export { Widget };
