import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMeasure from "react-use-measure";
import { animated, useSpring } from "@react-spring/web";

import {
  RequestOTPParams,
  SubmitOTPParams,
  useIsSignedInMutation
} from "@app/utils/query";
import { Button, Logo } from "@app/components";
import { getErrorMessage } from "@app/utils/errors";
import { Footer } from "../_Shared";

enum LoginStep {
  ENTER_EMAIL,
  ENTER_OTP
}

export const BetaSignup = () => {
  return (
    <main>
      <form
        className="auth auth--wider"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="_flex _flex-center _flex-spaceBetween">
          <Logo size="sm" />
          <h1 className="auth_badge">Beta Signup</h1>
        </div>

        <h2 className="auth_h2 ">Welcome to ShipThis Beta Signup.</h2>

        <p className="auth_p _mt0">
          ShipThis is a service that helps you to get your Godot game to the app
          store. <br />
          <br />
          We’ve build the basics but we will need to test it and improve it
          before launching fully. <br />
          <br />
          This beta signup offers you the following:
          <br />
        </p>

        <ul className="auth_features">
          <li>Lifetime membership</li>
          <li>Free support for the duration of the beta</li>
        </ul>

        <Button size="md" href="/login" centeredLoader>
          Continue
        </Button>
        <input type="submit" hidden />
      </form>
      <Footer className="auth_footer--wider" />
    </main>
  );
};
