import React, { useEffect, useState } from "react";

import { useFindJobById, useJobs } from "@app/utils/query";
import { Job, JobLogEntry, Project } from "@app/types";
import { JobListener, useWebSocket } from "@app/utils/hooks";
import { Pagination, Spinner } from "@app/components";

import { GameJob } from "../Job";

interface Props {
  projectId: Project["id"];
  jobId?: JobLogEntry["id"];
}

const usePaginatedJobs = (projectId: Project["id"], jobId: Job["id"]) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedItem, setSelectedItem] = useState<number | boolean>();
  const { data, isLoading } = useJobs(projectId, { pageNumber });
  const { foundOnPage, isSearching } = useFindJobById(projectId, jobId);

  // Navigate to the correct page when found via useFindJobById()
  useEffect(() => {
    if (jobId && foundOnPage > 0) setPageNumber(foundOnPage);
  }, [jobId, foundOnPage]);

  // Open the correct job after arriving on the correct page
  useEffect(() => {
    if (pageNumber === foundOnPage && !isLoading) openJobWithId();
    // Remove opened id from url?
    // const url = `//${window.location.host}/games/${projectId}`;
    // window.history.replaceState("", "", `${url}`);
  }, [pageNumber, foundOnPage, isLoading]);

  const openJobWithId = async () => {
    const index = data?.jobs?.findIndex((i) => i.id === jobId);
    if (index >= 0) setSelectedItem(index);
  };

  return {
    data,
    isLoading: isLoading || (jobId && isSearching),
    selectedItem,
    setSelectedItem,
    pageNumber,
    setPageNumber
  };
};

const GameJobs = ({ projectId, jobId }: Props) => {
  useWebSocket([new JobListener(projectId)]);

  const {
    data,
    isLoading,
    selectedItem,
    setSelectedItem,
    pageNumber,
    setPageNumber
  } = usePaginatedJobs(projectId, jobId);

  return (
    <section>
      {isLoading && <Spinner />}
      {data?.jobs?.map((job, i) => (
        <GameJob
          key={job.id}
          job={job}
          projectId={projectId}
          isActive={i === selectedItem}
          onSelect={() => setSelectedItem(i)}
        />
      ))}
      <Pagination
        totalPages={data?.pageCount}
        onSelect={(page) => {
          setSelectedItem(false);
          setPageNumber(page);
        }}
        activePage={pageNumber}
        className="_mt20"
      />
    </section>
  );
};

export { GameJobs };
