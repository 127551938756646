import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

export const Dashboard = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.646"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M3.52 10.89c.082 1.133.702 2.044 1.472 2.17.572.094 1.159.175 1.758.175.598 0 1.186-.08 1.758-.175.77-.126 1.39-1.037 1.472-2.17.06-.836.112-1.695.112-2.57 0-.876-.051-1.734-.112-2.57-.083-1.133-.702-2.045-1.472-2.17-.572-.095-1.16-.175-1.758-.175s-1.186.08-1.758.174c-.77.126-1.39 1.038-1.472 2.17a35 35 0 0 0-.113 2.57c0 .876.052 1.735.113 2.571m16.368 1.654c-.083-1.132-.704-2.043-1.476-2.169-.573-.094-1.162-.174-1.762-.174s-1.188.08-1.761.174c-.772.126-1.393 1.037-1.476 2.17-.06.835-.112 1.693-.112 2.568s.051 1.733.112 2.568c.083 1.133.704 2.043 1.476 2.17.573.093 1.161.173 1.761.173s1.189-.08 1.762-.174c.772-.126 1.393-1.037 1.476-2.169A35 35 0 0 0 20 15.113c0-.875-.051-1.733-.112-2.569M3.52 19.177c.082.41.702.738 1.472.784.572.034 1.159.063 1.758.063.598 0 1.186-.03 1.758-.063.77-.046 1.39-.375 1.472-.784.06-.302.112-.613.112-.93 0-.316-.051-.626-.112-.928-.083-.41-.702-.739-1.472-.784a30 30 0 0 0-1.758-.063c-.599 0-1.186.029-1.758.063-.77.045-1.39.375-1.472.784a4.7 4.7 0 0 0-.113.929c0 .316.052.627.113.929M19.888 4.252c-.083-.41-.704-.739-1.476-.784a30 30 0 0 0-1.762-.063c-.6 0-1.188.029-1.761.063-.772.045-1.393.375-1.476.784-.06.302-.112.613-.112.929s.051.627.112.929c.083.41.704.738 1.476.784a30 30 0 0 0 1.761.063c.6 0 1.189-.029 1.762-.063.772-.046 1.393-.375 1.476-.784.06-.302.112-.613.112-.929s-.051-.627-.112-.929" />
    </svg>
  );
};
