import { useState } from "react";

const delay = (time) => {
  return new Promise((res) => {
    setTimeout(res, time);
  });
};

export const useCopyToClipboard = () => {
  const [isCopying, setIsCopying] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const copyText = async (text: string) => {
    setIsCopying(true);
    navigator.clipboard.writeText(text);
    await delay(750);
    setIsCopying(false);
    setIsCopied(true);
    await delay(1000);
    setIsCopied(false);
  };

  return {
    copyText,
    isCopying,
    isCopied
  };
};
