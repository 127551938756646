import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { API_URL, cacheKeys } from "@app/constants";
import { castArrayObjectDates } from "@app/utils/dates";
import { Job, PageAndSortParams, Project } from "@app/types";

import { getAuthHeaders } from "./useIsSignedIn";

export interface JobsQueryResult {
  jobs: Job[];
  pageCount: number;
}

export async function fetchJobs(
  projectId: string,
  params: PageAndSortParams
): Promise<JobsQueryResult> {
  try {
    const headers = getAuthHeaders();
    const url = `${API_URL}/projects/${projectId}/jobs`;
    const { data } = await axios.get(url, {
      headers,
      params
    });
    return {
      jobs: castArrayObjectDates<Job>(data.data),
      pageCount: data.pageCount
    };
  } catch (error) {
    console.warn("fetchJobs Error", error);
    throw error;
  }
}

export const useJobs = (
  projectId: Project["id"],
  params: PageAndSortParams = {}
): UseQueryResult<JobsQueryResult> => {
  return useQuery<JobsQueryResult, AxiosError>({
    queryKey: cacheKeys.jobs(projectId, params),
    queryFn: () => fetchJobs(projectId, params),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60
  });
};

/*
  Find a specificic id in a list of paginated jobs and return it's page
*/

export const useFindJobById = (projectId: Project["id"], jobId: Job["id"]) => {
  const isSearching = useRef<boolean>(false); // Protect from reTriggering
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [foundOnPage, setFoundOnPage] = useState<number>(0);
  const [foundJob, setFoundJob] = useState<Job | undefined>();

  useEffect(() => {
    if (!isSearching.current) searchPage(0);
  }, [projectId, jobId, isSearching.current]);

  const setIsSearching = (newValue: boolean) => {
    isSearching.current = newValue;
  };

  const searchPage = async (pageNumber) => {
    setIsSearching(true);
    const data = await fetchJobs(projectId, { pageNumber });
    const r = data?.jobs?.filter((job) => job.id === jobId);
    if (r.length < 1 && pageNumber < data.pageCount) {
      searchPage(pageNumber + 1);
    } else {
      setFoundJob(r?.[0]);
      setIsLoading(false);
      setIsSearching(false);
      setFoundOnPage(pageNumber);
    }
  };

  return {
    isSearching: isLoading,
    foundJob,
    foundOnPage
  };
};
