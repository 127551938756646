import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, ShortName, Spinner } from "@app/components";
import { useProjects } from "@app/utils/query";
import { Section, SectionHeader as Header } from "@app/components";

const MyRecentProjects = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const { data, isLoading } = useProjects({
    pageNumber: 0,
    pageSize: 100,
    orderBy: "createdAt",
    order
  });

  const changeOrder = () => {
    setOrder(order === "asc" ? "desc" : "asc");
  };

  return (
    <Section variant="ghost">
      <Header>
        <div>
          <span className="_mr10">Recent Games</span>
          {isLoading && <Spinner />}
        </div>
        <Button onClick={changeOrder} size="sm" variant="dark">
          {order === "asc" ? "Order ▼" : "Order ▲"}
        </Button>
      </Header>

      <div className="list">
        {data?.projects?.map(({ id, name }) => (
          <Link
            key={id}
            className="list-item list--games-recent"
            to={`/games/${id}`}
          >
            <ShortName name={name} className="list-icon" />
            <div>{name}</div>
          </Link>
        ))}
      </div>
      <footer className="list-footer">
        <Button size="sm" onClick={() => navigate("/games")}>
          All Games
        </Button>
      </footer>
    </Section>
  );
};

export { MyRecentProjects };
