import { DateTime, DateTimeFormatOptions } from "luxon";

export const DEFAULT_LOCALE = "en-US";

// Converts the attributes of apiObject which are in the keys array to DateTime
export function castObjectDates<T>(
  apiObject: any,
  keys = ["createdAt", "updatedAt"]
) {
  if (!apiObject) return apiObject as T;
  const datesOnly = Object.keys(apiObject)
    .filter((k) => keys.includes(k))
    .reduce((a: any, c: string) => {
      a[c] = DateTime.fromISO(apiObject[c]);
      return a;
    }, {});
  return {
    ...apiObject,
    ...datesOnly,
  } as T;
}

// Converts the attributes of each object in apiArray which are in the keys array to DateTime
export function castArrayObjectDates<T>(
  apiArray: any[],
  keys = ["createdAt", "updatedAt"]
) {
  return apiArray.map((apiObject) => castObjectDates<T>(apiObject, keys));
}

export function getDateLocale() {
  return navigator.language || DEFAULT_LOCALE;
}

/**
 * Formats a DateTime object into a short time string, based on the current locale.
 * Extra formatting options can be provided to customize the output, including fractional seconds.
 */
export function getShortTime(
  inputDate: DateTime,
  extraFormatOpts: DateTimeFormatOptions = { fractionalSecondDigits: 3 }
) {
  const locale = getDateLocale();
  const formatOpts = { ...DateTime.TIME_24_WITH_SECONDS, ...extraFormatOpts };
  return inputDate.toLocaleString(formatOpts, { locale });
}
