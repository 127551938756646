import React from "react";
import { FormControl, Section, TextInput } from "@app/components";

const GameName = ({ formik }) => (
  <Section>
    <FormControl label="Name">
      <TextInput
        title="Name"
        placeholder="Space Invaders"
        value={formik.values.name}
        // isLoading={isDirty("name")}
        onChange={(v) => formik.setFieldValue("name", v)}
        onSubmit={formik.handleSubmit}
        isProtected
      />
    </FormControl>
  </Section>
);

export { GameName };
