import React, { useState } from "react";
import { Project } from "@app/types";
import { Pagination, Spinner } from "@app/components";
import { useBuilds } from "@app/utils/query";
import { Build } from "./Build";

interface Props {
  projectId: Project["id"];
}

const GameBuilds = ({ projectId }: Props) => {
  const pageSize = 10;

  const [pageNumber, setPageNumber] = useState(0);
  const { data, isLoading } = useBuilds({ projectId, pageNumber, pageSize });

  if (isLoading) return <Spinner />;

  return (
    <section>
      {isLoading && <Spinner />}
      {data?.data?.map((build) => (
        <Build key={build.id} build={build} />
      ))}
      <Pagination
        totalPages={data?.pageCount}
        onSelect={(page) => setPageNumber(page)}
        activePage={pageNumber}
        className="_mt20"
      />
    </section>
  );
};

export { GameBuilds };
