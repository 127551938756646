import React from "react";
import { Outlet, Route } from "react-router-dom";

import { GameList } from "./Games";
import { Game } from "./Game";
import { GameJobFull } from "./Job/Full";

export const GamesRoutes = () => (
  <>
    <Route path=":projectId/job/:jobId/full" element={<GameJobFull />} />
    <Route path=":projectId/job/:jobId" element={<Game />} />
    <Route path=":projectId/:tab" element={<Game />} />
    <Route path=":projectId" element={<Game />} />
    <Route path="" element={<GameList />} />
  </>
);

export const Games = () => <Outlet />;
