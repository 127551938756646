import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { ThumbsUp } from "@app/components/Icons";

interface Props extends React.ComponentProps<"div"> {
  style: any;
  isVisible: boolean;
}

const Done = ({ style, isVisible }: Props) => {
  const [styles] = useSpring(
    () => ({
      marginTop: isVisible ? 0 : -40,
      config: {
        friction: 6
      }
    }),
    [isVisible]
  );

  return (
    <animated.div className="feedback-page" style={style}>
      <div className="feedback-thanks">
        <animated.span className="feedback-thanksIcon _mr10" style={styles}>
          <ThumbsUp />
        </animated.span>
        Thank you, we'll be in touch!
      </div>
    </animated.div>
  );
};
export { Done };
