import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

export const GraphUp = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#000"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M22 10.5V12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2h1.5"
        strokeLinecap="round"
      />
      <path d="M19 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
      <path
        d="m7 14 2.293-2.293a1 1 0 0 1 1.414 0l1.586 1.586a1 1 0 0 0 1.414 0L17 10m0 0v2.5m0-2.5h-2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
