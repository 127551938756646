import React from "react";
import { animated } from "@react-spring/web";

interface Props extends React.ComponentProps<"a"> {
  style: any;
}

const SubmitButton = ({ children, onClick, style }: Props) => (
  <animated.a
    href="#"
    style={style}
    onClick={onClick}
    className="feedback-submitButton"
  >
    {children}
  </animated.a>
);

export { SubmitButton };
