import axios from "axios";

import { useState } from "react";

import { Build } from "@app/types";
import { getAuthHeaders } from "@app/utils/query";
import { API_URL } from "@app/constants";
import { castObjectDates } from "@app/utils/dates";

interface FetchBuildParams {
  buildId: string;
  projectId: string;
}

// TODO: move to a useBuild?
export async function fetchBuild({
  buildId,
  projectId,
}: FetchBuildParams): Promise<Build> {
  const headers = getAuthHeaders();
  const url = `${API_URL}/projects/${projectId}/builds/${buildId}/`;
  const response = await axios.get(url, { headers });
  const build = castObjectDates<Build>(response.data);
  return build;
}

// Trigger download of the IPA or APK in the browser
export function useDownloadBuild() {
  const [isLoading, setIsLoading] = useState(false);

  const downloadBuild = async ({ buildId, projectId }: FetchBuildParams) => {
    try {
      setIsLoading(true);
      const build = await fetchBuild({ buildId, projectId });
      window.open(build.url, "_blank");
    } catch (error) {
      console.warn("downloadBuild Error", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { downloadBuild, isLoading };
}
