import React, { useState } from "react";
import ColorHash from "color-hash";

interface Props extends React.ComponentProps<"div"> {
  name: string;
  length?: number;
}

const ShortName = React.memo(({ name, length = 2, className }: Props) => {
  const colorHash = new ColorHash({
    saturation: 0.7,
    lightness: 0.5
  });

  const shortName = name
    ?.split(" ")
    .map((p) => p.slice(0, 1))
    .slice(0, length);

  return (
    <div
      className={className}
      style={{ backgroundColor: colorHash.hex(shortName) }}
    >
      {shortName}
    </div>
  );
});

export { ShortName };
