import React from "react";
import { useState, useEffect } from "react";
import Markdown from "react-markdown";
import PrivacyPolicy from "@assets/docs/privacy.md";

const Privacy = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(PrivacyPolicy)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, []);

  return (
    <main>
      <section className="privacy">
        <Markdown>{content}</Markdown>
      </section>
    </main>
  );
};

export { Privacy };
