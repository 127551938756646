import React, { useState } from "react";
import {
  Credential,
  Section,
  TextInput,
  FormControl,
  Spinner
} from "@app/components";
import { Platform, Project } from "@app/types";
import { useProjectCredentials } from "@app/utils/query";

interface Props {
  projectId: Project["id"];
}

const GameCredentials = ({ projectId }: Props) => {
  const { data, isLoading } = useProjectCredentials(projectId, Platform.IOS);

  if (isLoading) return <Spinner />;

  return (
    <Section>
      {data?.projectCredentials?.map(({ id, type, serialNumber }) => (
        <FormControl
          key={id}
          label="Mobile Provisioning Profile"
          icon={
            <div className="formItem-credentialIcon">
              <Credential type={type} size={16} />
            </div>
          }
        >
          <TextInput value={serialNumber} onChange={() => {}} isProtected />
        </FormControl>
      ))}
    </Section>
  );
};

export { GameCredentials };
