import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

export const SquareArrowRightUp = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#000"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="m9 15 6-6m0 0h-4.5M15 9v4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2s7.071 0 8.535 1.464C22 4.93 22 7.286 22 12s0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12Z" />
    </svg>
  );
};
