import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Expand = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="black"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M21 21L15 15M21 21V16.2M21 21H16.2" />
    <path d="M3 16.2V21M3 21H7.8M3 21L9 15" />
    <path d="M21 7.8V3M21 3H16.2M21 3L15 9" />
    <path d="M3 7.8V3M3 3H7.8M3 3L9 9" />
  </svg>
);

export { Expand };
