import React, { useState } from "react";
import axios from "axios";

import { API_URL } from "@app/constants";
import { getErrorMessage } from "@app/utils/errors";
import { getAuthHeaders } from "@app/utils/query";
import { useAndroidStartSetup } from "@app/utils/hooks";
import { TextInput } from "@app/components";

export const AndroidSetupButton = ({ projectId }: { projectId: string }) => {
  const [error, setError] = useState<undefined | string>();
  const [developerId, setDeveloperId] = useState<string>("");
  const { handleStartSetup } = useAndroidStartSetup(projectId, setError);

  const baseApiUrl = `${API_URL}/projects/${projectId}/credentials/android/key`;

  const handleTestSetup = async () => {
    try {
      const url = `${baseApiUrl}/test/`;
      const headers = getAuthHeaders();
      await axios.post(url, {}, { headers });
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  const handleInviteServiceAccount = async () => {
    try {
      const url = `${baseApiUrl}/invite/`;
      const headers = getAuthHeaders();
      await axios.post(url, { developerId }, { headers });
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  return (
    <>
      {error && <h1 style={{ color: "red" }}>{error}</h1>}
      <button onClick={handleStartSetup}>Android Setup</button>
      <br />
      <button onClick={handleTestSetup}>Test Android Setup</button>
      <br />
      <h2>Developer ID:</h2>
      <form onSubmit={handleInviteServiceAccount}>
        <TextInput
          title="Developer ID"
          value={developerId}
          onChange={(e) => setDeveloperId(e.target.value)}
          placeholder="219487129847129847"
        />
        <input type="submit" value="submit" />
      </form>
    </>
  );
};
