import React from "react";
import classNames from "classnames";
import { Button } from "@app/components";

interface Props extends React.ComponentProps<"div"> {
  activePage: number;
  totalPages: number;
  onSelect: (page: any) => void;
}

const Pagination = ({ activePage, totalPages, onSelect, ...props }: Props) => {
  if (!totalPages || totalPages === 1) return null;

  return (
    <div {...props}>
      {[...Array(totalPages)].map((_, page) => (
        <Button
          key={page}
          className={classNames("button--pagination", {
            "button--paginationActive": activePage === page
          })}
          onClick={() => onSelect(page)}
        >
          {page + 1}
        </Button>
      ))}
    </div>
  );
};

export { Pagination };
