import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";

interface ToggleProps extends React.ComponentProps<"div"> {
  value: string;
  values: string[];
  onChange: (value: any) => void;
}

const Toggle = ({ value, values, onChange, ...props }: ToggleProps) => {
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const isInitialSelect = useRef(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [sliderStyles] = useSpring(
    () => ({
      translateX: itemRefs?.current?.[selectedIndex]?.offsetLeft || 0,
      width: itemRefs.current?.[selectedIndex]?.offsetWidth || 0,
      immediate: isInitialSelect.current,
      config: { tension: 200 }
    }),
    [selectedIndex]
  );

  // Initialize: Display current selected value
  // after we have all ref info etc

  useEffect(() => {
    if (itemRefs.current.length === values.length) {
      setSelectedIndex(values.indexOf(value) || 0);
    }
  }, [itemRefs.current]);

  // Report back upstairs

  useEffect(() => {
    if (selectedIndex >= 0) {
      onChange(values[selectedIndex]);
      isInitialSelect.current = false;
    }
  }, [selectedIndex]);

  return (
    <div className="toggleWrap" {...props}>
      <div className="toggle">
        <animated.div className="toggle-slider" style={sliderStyles} />
        {values.map((value, i) => (
          <animated.div
            key={value}
            ref={(el) => (itemRefs.current[i] = el)}
            onClick={() => setSelectedIndex(i)}
            className={classNames("toggle-option", {
              "toggle-option--selected": selectedIndex === i
            })}
          >
            {value}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export { Toggle };
