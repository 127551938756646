import classNames from "classnames";
import React from "react";

interface Props extends React.ComponentProps<"div"> {
  className?: string;
  children: React.ReactNode;
}

const Terminal = ({ className, children, ...props }: Props) => {
  return (
    <div className={classNames("terminal", className)} {...props}>
      <div className="terminal__body">{children}</div>
    </div>
  );
};

export { Terminal };
