import classNames from "classnames";
import React from "react";

interface Props extends React.ComponentProps<"section"> {
  variant?: "ghost";
}

const Section = ({ variant, children, className }: Props) => {
  const variantClass = variant && `admin-section--${variant}`;

  return (
    <section className={classNames("admin-section", className, variantClass)}>
      {children}
    </section>
  );
};

export { Section };
