import React from "react";
import { Header } from "../_Shared";
import { useUserCredentials } from "@app/utils/query";

import { SimpleCredentialList } from "@app/components";

const Credentials = () => {
  const { data, isLoading } = useUserCredentials();

  return (
    <main className="admin-main">
      <Header title="Credentials" />
      <div className="grid  admin__grid">
        <section className="admin-section">
          <p>
            These credentials are those which are global to all of your games.
            For example, you may want to release multiple titles to the App
            Store with the same team distribution certificate.
          </p>
          <SimpleCredentialList
            list={data?.userCredentials}
            isLoading={isLoading}
            pageNumber={0}
            pageCount={data?.pageCount}
          />
        </section>
      </div>
      <div className="grid grid--2col admin__grid">
        <section className="admin-section">
          <div>
            <h2>iOS distribution cert</h2>
            <p>Details of that thing</p>
            <div style={{ color: "red" }}>Missing</div>
            <a href="">Disable</a>
          </div>
        </section>
        <section className="admin-section">
          <div>
            <h2>Android service account key for submitting to the playstore</h2>
            <p>Details of that thing</p>
            <div style={{ color: "green" }}>Found</div>
            <a href="">Disable</a>
          </div>
        </section>
      </div>
      <section className="admin-section">
        <p>
          Missing those? You cannot upload these manually, just run 'npx
          shipthis configure ios' or 'npx shipthis configure android' in your
          terminal.
          <br />
          <br />
          How to regenerate, how to invalidate? to generate run the command for
          the plaform: 'npx shipthis configure ios' to regenerate tap disable
          and run the command 'npx shipthis configure ios'
        </p>
      </section>
    </main>
  );
};

export { Credentials };
