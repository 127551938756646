import React from "react";
import { Link } from "react-router-dom";
import { Avatar, ShortName, Spinner } from "@app/components";
import { GameEngine } from "@app/components/Icons";
import { useProjects } from "@app/utils/query";

import { Header } from "../_Shared";

export const GameList = () => {
  const { data, isLoading } = useProjects();

  return (
    <main className="admin-main">
      <Header title="Games" />
      <section>
        {isLoading && <Spinner />}
        <div className="grid game__grid">
          {data?.projects?.map(({ id, name, details }) => (
            <Link key={id} className="game" to={`/games/${id}`}>
              <ShortName name={name} className="game-shortName" />
              <div>
                {name}
                <span className="_ml5">
                  {details?.semanticVersion || "0.0.1"} [Build:{" "}
                  {details?.buildNumber || 1}]
                </span>
                <div className="game-details">{details?.iosBundleId}</div>
                <Avatar size={28} className="game-avatar" />
                <div className="game-engine">
                  {details?.gameEngine && details?.gameEngineVersion && (
                    <>
                      <GameEngine type={details?.gameEngine} />
                      {details?.gameEngineVersion}
                    </>
                  )}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </main>
  );
};
