import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {}

export const Tag = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="#000"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path d="M4.728 16.137c-1.545-1.546-2.318-2.318-2.605-3.321-.288-1.003-.042-2.068.45-4.197l.283-1.228c.413-1.792.62-2.688 1.233-3.302s1.51-.82 3.302-1.233l1.228-.284c2.13-.491 3.194-.737 4.197-.45 1.003.288 1.775 1.061 3.32 2.606l1.83 1.83C20.657 9.248 22 10.592 22 12.262c0 1.671-1.344 3.015-4.033 5.704-2.69 2.69-4.034 4.034-5.705 4.034-1.67 0-3.015-1.344-5.704-4.033z" />
        <path d="M10.021 10.293a2 2 0 1 0-2.828-2.828 2 2 0 0 0 2.828 2.828Z" />
        <path d="m11.542 18.5 6.979-6.98" strokeLinecap="round" />
      </g>
    </svg>
  );
};
