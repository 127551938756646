import React, { useState } from "react";

import { useDownloadCredential } from "@app/utils/hooks";
import { getErrorMessage } from "@app/utils/errors";
import { ProjectCredential, UserCredential } from "@app/types";

interface Props {
  list: UserCredential[] | ProjectCredential[];
  isLoading: boolean;
  projectId?: string;
  // TODO: use these
  pageNumber: number;
  pageCount: number;
}

const SimpleCredentialList = ({ list, isLoading, projectId }: Props) => {
  const [error, setError] = useState<undefined | string>();

  const { downloadCredential, isLoading: isDownloading } =
    useDownloadCredential();

  const handleDownloadPress = async (credentialId: string) => {
    try {
      await downloadCredential({ credentialId, projectId });
    } catch (error) {
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  return (
    <>
      {error && <p color="red">{error}</p>}
      <table>
        <thead>
          <tr>
            <th>Platform</th>
            <th>Type</th>
            <th>Serial Number</th>
            <th>Details</th>
            <th>isActive</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={6}>Loading...</td>
            </tr>
          )}
          {list &&
            list.map((credential) => (
              <tr key={credential.id}>
                <td>{credential.platform}</td>
                <td>{credential.type}</td>
                <td>{credential.serialNumber}</td>
                <td>{JSON.stringify(credential.details)}</td>
                <td>{credential.isActive ? "Yes" : "No"}</td>
                <td>
                  <a
                    href="#"
                    onClick={() => handleDownloadPress(credential.id)}
                  >
                    Download
                  </a>
                </td>
              </tr>
            ))}
          {isDownloading && (
            <tr>
              <td colSpan={6}>Downloading...</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export { SimpleCredentialList };
