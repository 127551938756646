import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { getAuthHeaders } from "@app/utils/query";
import { getErrorMessage, isAbortError } from "@app/utils/errors";
import { API_URL } from "@app/constants";
import {
  GoogleOAuthPersistTokenRequest,
  GoogleOAuthPersistTokenResponse,
  GoogleOAuthRedirectResponse
} from "@app/types";

export const GoogleRedirect = () => {
  const [error, setError] = useState<undefined | string>();
  const navigate = useNavigate();

  const handlePageLoad = async (abortController: AbortController) => {
    try {
      const queryString = window.location.search;
      const query: GoogleOAuthRedirectResponse = Object.fromEntries(
        new URLSearchParams(queryString)
      );

      if (query.error) throw new Error(query.error);
      if (!query.code) throw new Error("Missing 'code' from query params");

      // Send the code to the backend - it will exchange for a token and save it
      const base = new URL("/", window.location.href).href;
      const redirectUri = `${base}google/redirect/`;
      const persistTokenRequest: GoogleOAuthPersistTokenRequest = {
        code: query.code,
        redirectUri
      };

      const headers = getAuthHeaders();
      const opt = { headers, signal: abortController.signal };
      const { data } = await axios.post(
        `${API_URL}/me/google/connect`,
        persistTokenRequest,
        opt
      );
      const response: GoogleOAuthPersistTokenResponse = data;

      // Start the android api-key setup process
      const startUrl = `${API_URL}/projects/${response.projectId}/credentials/android/key/setup/`;
      await axios.post(startUrl, {}, opt);
      // Redirect to the project android setup page
      const url = `/games/${response.projectId}/?showAndroid=1`;
      navigate(url);
    } catch (error) {
      if (isAbortError(error)) return;
      console.warn(error);
      setError(getErrorMessage(error));
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    handlePageLoad(abortController);
    return () => abortController.abort();
  }, []);

  return (
    <>
      <h1>ShipThis Google Redirect</h1>
      {error && <h1 style={{ color: "red" }}>{error}</h1>}
    </>
  );
};
