import React, { useEffect } from "react";
import Ansi from "ansi-to-react";
import classNames from "classnames";

import { Spinner } from "@app/components";
import { Job, Project } from "@app/types";
import { useJobLogsInfinite } from "@app/utils/query";
import { useTopReached } from "@app/utils/hooks/useTopReached";

import { JobLogListener, useWebSocket } from "@app/utils/hooks";
import { getShortTime } from "@app/utils/dates";

interface Props {
  projectId: Project["id"];
  jobId: Job["id"];
  isSubscribed?: boolean;
  showEntireLog?: boolean;
  logRef?: any;
}

const Log = ({
  projectId,
  jobId,
  isSubscribed,
  showEntireLog,
  logRef
}: Props) => {
  // Only listen to websocket messages if the job is pending or processing
  const listeners = isSubscribed ? [new JobLogListener(projectId, jobId)] : [];
  useWebSocket(listeners);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useJobLogsInfinite({ projectId, jobId });

  const { ref, onScrollEvent } = useTopReached(
    hasNextPage,
    fetchNextPage,
    data?.pageParams?.length
  );

  const noLog = !data?.pages?.[0]?.[0]?.id;

  // Fetch all pages in fullscreen mode and keep the
  // screen on the bottom of the page
  useEffect(() => {
    if (showEntireLog && hasNextPage) fetchNextPage();
  }, [data, showEntireLog, hasNextPage]);

  // Logs sent via WS can be out of order
  const srt = (logs) =>
    logs.sort((a, b) => b.sentAt.toMillis() - a.sentAt.toMillis());

  return (
    <div className="_relative" ref={logRef}>
      {isFetchingNextPage && <Spinner className="job-log-spinner" />}
      <div
        ref={ref}
        className={classNames(
          "job-log",
          { "job-log--noLogData": noLog },
          { "job-log--fullScreen": showEntireLog }
        )}
        onScroll={onScrollEvent}
      >
        {noLog && <div className="job-log-text">Nothing logged yet</div>}
        {data?.pages?.map((page) => {
          /* TODO: highlight where level === "ERROR" */
          return srt(page).map(({ id, level, message, sentAt, stage }) => {
            return (
              <div
                key={id}
                className={classNames("job-log-line", {
                  "job-log-line--error": level === "ERROR"
                })}
              >
                <span className="job-log-hilight">{getShortTime(sentAt)}</span>
                <span
                  className={classNames(
                    "job-log-stage",
                    `job-log-stage--${stage}`
                  )}
                >
                  {stage}
                </span>
                <span className="_hidden"> </span>
                {/* TODO: this fixes tabbing but clips long lines */}
                <pre style={{ margin: 0 }}>
                  <Ansi className="job-log-text">{message}</Ansi>
                </pre>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};

export { Log };
