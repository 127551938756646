import React, { useEffect, useRef, useState } from "react";

const useTopReached = (hasNext: boolean, fetchNext: () => void, pageIndex) => {
  const ref = useRef(null).current;
  const thresh = useRef(2000).current;
  const lastPageIndex = useRef(null);

  /* Trigger next page load straight away? */

  useEffect(() => {
    if (ref?.clientHeight <= ref?.scrollHeight && hasNext) fetchNext();
  }, [ref]);

  /* Load additional pages */

  const onScrollEvent = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const topLoc = -(scrollHeight - clientHeight) + thresh;
    if (scrollTop <= topLoc && hasNext) {
      // Prevent overtriggering, only run once
      if (pageIndex != lastPageIndex.current) {
        fetchNext();
        lastPageIndex.current = pageIndex;
      }
    }
  };

  return { ref, onScrollEvent };
};

export { useTopReached };
