import React from "react";
import classNames from "classnames";
import { DateTime } from "luxon";
import { Clock } from "@app/components";

interface Props extends React.ComponentProps<"div"> {
  date: DateTime;
  shorter?: boolean;
  showIcon?: boolean;
  showAgo?: boolean;
}

const TimeAgo = ({
  date,
  showIcon,
  shorter,
  showAgo,
  className,
  ...props
}: Props) => {
  const formattedDate = DateTime.fromISO(date)
    .toRelative()
    // .toLocaleString({ month: "short", day: "numeric" });
    .replace(shorter && " days", "d")
    .replace(!showAgo && "ago", "");

  return (
    <div className={classNames("date", className)} {...props}>
      {showIcon && <Clock stroke="white" width={14} className="_mr5" />}
      {formattedDate}
    </div>
  );
};

export { TimeAgo };
