import React from "react";
import classNames from "classnames";

import { useProject } from "@app/utils/query";
import { Project, JobStatus, Job } from "@app/types";

import { JobHeader } from "./Header";
import { JobContent } from "./Content";

interface Props {
  job: Job;
  isActive?: boolean;
  onSelect: () => void;
  projectId: Project["id"];
}

const GameJob = ({ job, projectId, isActive, onSelect }: Props) => {
  const { data: project } = useProject(projectId);

  // If we need to listen to websocket messages
  const isSubscribed = [JobStatus.PENDING, JobStatus.PROCESSING].includes(
    job.status
  );

  return (
    <div
      className={classNames("job", {
        "job--selected": isActive
      })}
    >
      <JobHeader
        job={job}
        project={project}
        onSelect={onSelect}
        isSubscribed={isSubscribed}
      />
      <JobContent
        isActive={isActive}
        project={project}
        job={job}
        isSubscribed={isSubscribed}
      />
    </div>
  );
};

export { GameJob };
