import { Button, PopUp } from "@app/components/";

const UnlockPopup = ({ title, open, unlock, onClose }) => (
  <PopUp
    open={open}
    onClose={onClose}
    title={`Unlock ${title || "this field"}?`}
  >
    <div className="popup-buttonGroup">
      <Button size="md" onClick={onClose} isCancel>
        Cancel
      </Button>
      <Button size="md" onClick={unlock}>
        Unlock
      </Button>
    </div>
  </PopUp>
);

export { UnlockPopup };
