import axios, { AxiosError } from "axios";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { cacheKeys } from "@app/constants";
import { JobLogEntry } from "@app/types";
import { fetchJobLogs, JobLogsQueryParams } from "./useJobLogs";

export const useJobLogsInfinite = ({
  projectId,
  jobId,
  cursor,
  pageSize,
}: JobLogsQueryParams) =>
  useInfiniteQuery<JobLogEntry[], AxiosError>({
    queryKey: cacheKeys.jobLogEntries(jobId, cursor, "infinite"),
    queryFn: ({ pageParam: cursor }) =>
      fetchJobLogs({ projectId, jobId, cursor, pageSize }),
    getNextPageParam: (lastPage) => lastPage?.at(-1)?.id,
    initialPageParam: undefined,
  });
