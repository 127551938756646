import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const Phone = (props: Props) => (
  <svg
    width={28}
    height={27}
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0082 20.2353H14.0195M8.39549 2.27451H19.621C20.8609 2.27451 21.8661 3.27967 21.8661 4.51961V22.4804C21.8661 23.7203 20.8609 24.7255 19.621 24.7255H8.39549C7.15556 24.7255 6.15039 23.7203 6.15039 22.4804V4.51961C6.15039 3.27967 7.15556 2.27451 8.39549 2.27451Z"
      stroke="white"
      strokeWidth={2.2451}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { Phone };
