import React, { useEffect } from "react";
import classNames from "classnames";
import ColorHash from "color-hash";
import { useSelf } from "@app/utils/query";
import { useLocalStorage } from "@app/utils/hooks";

interface Props extends React.ComponentProps<"div"> {
  size?: number;
}

const Avatar = ({ size = 40, className, children, ...props }: Props) => {
  const { isLoading, data: self } = useSelf();
  const [myString, setMyString] = useLocalStorage("myString", "");
  const initials = isLoading ? myString : self?.email?.slice(0, 2);

  useEffect(() => {
    setMyString(initials);
  }, [initials]);

  const colorHash = new ColorHash({
    saturation: 0.8,
    lightness: 0.6,
    hue: { min: 0, max: 360 }
  });

  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundColor: myString ? colorHash.hex(myString) : "#333"
      }}
      className={classNames("avatar", className)}
      {...props}
    >
      {initials}
      {children}
    </div>
  );
};

export { Avatar };
