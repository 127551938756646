import React from "react";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";

// https://www.youtube.com/watch?v=ZS9iE0b-wpw

interface Props {
  children?: React.ReactNode;
}

const Portal = ({ children }: Props) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (!container) {
      setContainer(document.createElement("div"));
      return;
    }
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return container ? ReactDOM.createPortal(children, container) : null;
};

export { Portal };
