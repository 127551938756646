import React, { useRef, useState } from "react";
import classNames from "classnames";

import { animated, useTrail } from "@react-spring/web";
import { Check, Locked, X } from "@app/components/Icons";
import { UnlockPopup, Spinner } from "@app/components";

interface Props extends React.ComponentProps<"div"> {
  title?: string;
  type?: "text" | "number";
  value: string;
  capitalize?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  isProtected?: boolean;
  onChange: (e) => void;
  onSubmit?: (e) => void;
}

const TextInput = ({
  title,
  type = "text",
  value,
  capitalize,
  isLoading,
  placeholder,
  onChange,
  onSubmit,
  isProtected,
  className
}: Props) => {
  const originalValue = useRef(value);
  const inputRef = useRef<HTMLInputElement>();
  const [isLocked, setIsLocked] = useState(isProtected);
  const [showUnlockPopup, setShowUnlockPopup] = useState(false);

  const updateButtonStyles = useTrail(2, {
    translateY: isLocked ? -40 : 0,
    reverse: isLocked,
    config: {
      tension: 350,
      friction: 30
    }
  });

  // Formik also has a field called
  // initialValues, which holds all this.

  const undo_keepPreviousValue = () => {
    originalValue.current = inputRef.current.value;
  };

  const undo_recallPreviousValue = () => {
    onChange(originalValue.current);
  };

  const handleUnlock = () => {
    if (isProtected && isLocked && !isLoading) setShowUnlockPopup(true);
  };

  const unlock = async () => {
    await setIsLocked(false);
    await setShowUnlockPopup(false);
    inputRef.current.focus();
  };

  const handleSubmit = (e) => {
    onSubmit(e); // Mutate
    undo_keepPreviousValue();
    setIsLocked(true);
    e.preventDefault();
  };

  const handleCancel = (e) => {
    undo_recallPreviousValue();
    setIsLocked(true);
    e.preventDefault();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 27) handleCancel(e); // ESC
    if (e.keyCode === 13) handleSubmit(e); // Enter
  };

  return (
    <>
      <UnlockPopup
        title={title}
        open={showUnlockPopup}
        onClose={() => setShowUnlockPopup(false)}
        unlock={unlock}
      />
      <div
        onClick={handleUnlock}
        className={classNames("formItem-inputContainer", className, {
          "formItem-inputContainer--locked": isProtected && isLocked
        })}
      >
        <div className="formItem-lock">
          {isLoading && <Spinner />}
          {isProtected && isLocked && !isLoading && (
            <Locked
              isLocked={isLocked}
              size={12}
              stroke="#adb6c8"
              style={{
                position: "relative",
                top: 2
              }}
            />
          )}
        </div>
        <input
          ref={inputRef}
          type={type}
          value={value}
          className={classNames("formItem-input", { _capitalize: capitalize })}
          onChange={(e) => onChange(e.target.value)}
          disabled={isLocked}
          placeholder={placeholder}
          // Keys
          onKeyDown={onKeyDown}
          onSubmit={handleSubmit}
        />

        <div className="formItem-updateButtons">
          <animated.div
            onClick={handleSubmit}
            className="formItem-updateValue formItem-updateValue--update"
            style={updateButtonStyles[1]}
          >
            <Check width={15} height={15} strokeWidth={3} stroke="white" />
          </animated.div>
          <animated.div
            onClick={handleCancel}
            className="formItem-updateValue formItem-updateValue--cancel"
            style={updateButtonStyles[0]}
          >
            <X stroke="white" width={15} height={15} />
          </animated.div>
        </div>
      </div>
    </>
  );
};
export { TextInput };
