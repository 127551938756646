import React, { createContext } from "react";
import classNames from "classnames";
import { Chat, Undo } from "../../Icons";

interface Props extends React.ComponentProps<"div"> {
  label?: string;
  clipLabel?: boolean;
  isInvalid?: boolean;
  hasInfo?: boolean;
  children: React.ReactNode;
  icon?: React.ReactNode;
  altIcon?: React.ReactNode;
}

const Context = createContext({
  isInvalid: false
});

const FormControl = ({
  className,
  children,
  icon,
  altIcon,
  label,
  clipLabel,
  isInvalid,
  hasInfo
}: Props) => {
  return (
    <Context.Provider value={{ isInvalid }}>
      <label className={classNames("formItem", className)}>
        <div
          className={classNames("formItem-label", {
            "formItem-label--clip": clipLabel
          })}
        >
          <div className="_flex _flex-center">
            {icon && <div className="_mr5">{icon}</div>}
            {label && <div>{label}</div>}
          </div>
          {altIcon}
          <div className="_flex">
            {hasInfo && (
              <a href="#" className="formItem-info">
                <Undo stroke="beige" width={16} height={16} />
              </a>
            )}
            {hasInfo && (
              <a href="#" className="formItem-info">
                <Chat color="#f5567c" width={16} height={16} />
              </a>
            )}
          </div>
        </div>
        {children}
      </label>
    </Context.Provider>
  );
};

export { FormControl };
