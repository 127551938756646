import classNames from "classnames";
import React from "react";

interface Props extends React.ComponentProps<"section"> {}

const SectionHeader = ({ className, children }: Props) => (
  <section className={classNames("admin-section-header", className)}>
    {children}
  </section>
);

export { SectionHeader };
