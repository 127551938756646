import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { cacheKeys } from "@app/constants";
import { JobLogEntry, JobStage } from "@app/types";
import { fetchJobLogs, JobLogsQueryParams } from "./useJobLogs";

export const useLastJobStage = ({
  projectId,
  jobId,
  cursor = "",
  pageSize = 1,
  ...props
}: JobLogsQueryParams): UseQueryResult<any> => {
  // TODO: Couldn't get this to work with JobLogEntry[] | JobStage
  return useQuery<JobLogEntry[] | JobStage, AxiosError>({
    queryKey: cacheKeys.jobLogEntries(jobId, cursor, "lastItem"),
    queryFn: () => fetchJobLogs({ projectId, jobId, cursor, pageSize }),
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
    enabled: !!projectId && !!jobId,
    select: (data: JobLogEntry[]) => data?.[0]?.stage
  });
};
