import React from "react";
import classNames from "classnames";

import { useLastJobStage } from "@app/utils/query";
import { Job, JobStage, Project } from "@app/types";
import { animated } from "@react-spring/web";
import { useTimelineAnimation } from "./useTimelineAnimation";

interface Props extends React.ComponentProps<"div"> {
  projectId: Project["id"];
  jobId: Job["id"];
}

export const STAGES = Object.keys(JobStage);

const Timeline = ({ projectId, jobId, className }: Props) => {
  const { data: lastStage } = useLastJobStage({ projectId, jobId });
  const { springValues } = useTimelineAnimation(lastStage);

  return (
    <>
      <div className={classNames("timeline", className)}>
        {STAGES.map((s: JobStage, i: number) => {
          const isActiveStage = s === lastStage;
          const isSuccess = STAGES.indexOf(lastStage) === STAGES.length - 1;

          return (
            <animated.span
              key={s}
              style={springValues[i]}
              className={classNames("timeline-step", {
                "timeline-step--active": isActiveStage,
                "timeline-step--todo":
                  STAGES.indexOf(s) > STAGES.indexOf(lastStage),
                "timeline-step--success": isActiveStage && isSuccess
              })}
            >
              {isActiveStage && STAGES.indexOf(lastStage) + 1}
            </animated.span>
          );
        })}
      </div>
    </>
  );
};

export { Timeline };
