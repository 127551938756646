import { SVGProps } from "react";
import { Platform } from "@app/types/api";
import { Apple } from "../Apple";
import { Android } from "../Android";

interface Props extends SVGProps<SVGSVGElement> {
  platform: Platform;
  size?: number;
}

const PhoneOs = ({ platform, size = 24, ...props }: Props) => {
  const Component = { IOS: Apple, ANDROID: Android }[platform];
  return <Component {...props} width={size} height={size} />;
};

export { PhoneOs };
