import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import useMeasure from "react-use-measure";
import { useFormik } from "formik";
import { animated } from "@react-spring/web";

import { useSelf } from "@app/utils/query";
import { useFeedbackState } from "@app/utils/emitters";

import { useFeedbackAnimation } from "./Hooks/useFeedbackAnimation";
import { OpenButton } from "./Components/OpenButton";
import { CloseButton } from "./Components/CloseButton";
import { EnterDetails as EnterDetailsPage } from "./Pages/EnterDetails";
import { ExtraDetails as ExtraDetailsPage } from "./Pages/ExtraDetails";
import { Done as DonePage } from "./Pages/Done";
import { Widget } from "./Components/Widget";

import "./feedback.css";
import { API_URL } from "@app/constants";
import axios from "axios";
import { useLocation } from "react-router-dom";

// TODO
// [x] Tags funk
// [x] Formik
// [x] Pager
// [x] Folders/Structure
// [x] Page 2 - extra details
// [x] restart at page 1 ( after finish only )
// [x] Page 2 - thank you or close animation with scrolling thank you in the box?
// [x] Done - autoclose after x ms
// [-] Measure each page
// [x] populate additional data ( details object in formik? )
// [-] hover states
// [-] tag select animation (i think i have a problem with mutations)
// [x] mobile view, seems fine, need to test on real device
// [x] actual submit!
// [x] Message validation
// [x] Only select one tag.
// [x] also tag re-select = deselect.
// [>] clear form after submit
// [x] send along current page in details

const Feedback = () => {
  const timerRef = useRef<any>();
  const [contentRef, { width, height }] = useMeasure();
  const { isShowing, setIsShowing } = useFeedbackState();
  const [page, setPage] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const { data: self } = useSelf();

  const {
    contentStyles,
    sectionStyles,
    buttonStyles,
    pageStyles,
    submitButtonStyles
  } = useFeedbackAnimation(isShowing, width, height, page, isFinished);

  const validationSchema = yup.object().shape({
    message: yup.string().min(5).required("Required")
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
      tags: "",
      email: self?.email || "",
      sendDetails: true,
      details: {
        userId: self?.id,
        page: window.location.href
      }
    },
    validationSchema: validationSchema,
    async onSubmit(values, { resetForm }) {
      await axios.post(`${API_URL}/contact`, values).then(() => {
        resetForm();
      });
    }
  });

  const toggleFeedback = (e) => {
    e.preventDefault();
    setIsShowing(!isShowing);
    if (timerRef.current) clearTimeout(timerRef.current);
    if (isFinished) rewind();
  };

  const rewind = () => {
    setPage(0);
    formik.setFieldValue("message", "");
    formik.setFieldValue("email", "");
    setIsFinished(false);
  };

  const gotoNextPage = (e) => {
    e.preventDefault();
    setPage(page + 1);
  };

  const gotoPrevPage = (e) => {
    e.preventDefault();
    setPage(page - 1);
  };

  const gotoFinish = (e) => {
    e.preventDefault();
    setPage(2);
  };

  const finish = async (e) => {
    e.preventDefault();
    formik.handleSubmit();
    gotoFinish(e);
    setIsFinished(true);
    timerRef.current = setTimeout(toggleFeedback, 3000, e);
  };

  return (
    <Widget isShowing={isShowing} style={sectionStyles}>
      <OpenButton onClick={toggleFeedback} style={buttonStyles} />
      <animated.div className="feedback-contentWrap" style={contentStyles}>
        <div ref={contentRef} className="feedback-content">
          {/* Page 1 */}
          <EnterDetailsPage
            style={pageStyles}
            formik={formik}
            finish={finish}
            gotoNextPage={gotoNextPage}
            submitButtonStyles={submitButtonStyles}
          />
          {/* Page 2 */}
          <ExtraDetailsPage
            style={pageStyles}
            formik={formik}
            gotoPrevPage={gotoPrevPage}
            gotoNextPage={gotoNextPage}
            finish={finish}
            submitButtonStyles={submitButtonStyles}
          />
          {/* Page 3 */}
          <DonePage style={pageStyles} isVisible={page === 2} />
        </div>
        <CloseButton onClick={toggleFeedback} />
      </animated.div>
    </Widget>
  );
};

export { Feedback };
