import React from "react";
import { FormikProps } from "formik";
import { animated, useSpring } from "@react-spring/web";

import { Tags } from "../Components/Tags";
import { SubmitButton } from "../Components/SubmitButton";
import { ArrowLeft, LogoSymbol2 } from "@app/components/Icons";

interface TextareaError extends React.ComponentProps<"p"> {
  isErroring: boolean;
}

const TextareaError = ({ className, children, isErroring }) => {
  const style = useSpring({
    translateY: isErroring ? 0 : 10,
    opacity: isErroring ? 1 : 0
  });

  return (
    <animated.p className={className} style={style}>
      {children}
    </animated.p>
  );
};

interface Props extends React.ComponentProps<"div"> {
  style: any;
  formik: FormikProps<any>;
  finish: (e: any) => void;
  gotoNextPage: (e: any) => void;
  submitButtonStyles: any;
}

const EnterDetails = ({
  style,
  formik,
  finish,
  gotoNextPage,
  submitButtonStyles
}: Props) => {
  const onSubmit = async (e) => {
    e.preventDefault();
    // Handle Errors
    formik.setFieldTouched("message", true, true);

    if (!formik.errors.message) {
      // If we have the email, then just skip the next step and finish
      if (formik.values?.email) {
        gotoNextPage(e);
        finish(e);
      } else {
        gotoNextPage(e);
      }
    }
  };

  console.log("errors", formik.errors);

  return (
    <animated.div className="feedback-page" style={style}>
      {/* <LogoSymbol2 white="#2E2626" /> */}
      <h1 className="feedback-h1">Hi there. </h1>
      <p className="feedback-p">Got a question? Send us a message!</p>
      <div className="_relative">
        <TextareaError
          className="feedback-input-error"
          isErroring={formik.errors.message && formik.touched.message}
        >
          Please enter at least 5 characters.
        </TextareaError>
        <textarea
          className="feedback-input"
          value={formik.values.message}
          onChange={(e) => formik.setFieldValue("message", e.target.value)}
          placeholder="Type here..."
        />
      </div>
      <Tags setSelectedTags={(tags) => formik.setFieldValue("tags", tags)} />
      <div className="feedback-footer">
        <SubmitButton onClick={onSubmit} style={submitButtonStyles}>
          Next
          <ArrowLeft
            className="_hFlip _ml5"
            stroke="white"
            width="20"
            height="20"
          />
        </SubmitButton>
      </div>
    </animated.div>
  );
};

export { EnterDetails };
