import React from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Section,
  SectionHeader as Header,
  ShortName,
  Spinner,
  STATE_COLORS,
  Status,
  TimeAgo
} from "@app/components";
import { getVersionInfo } from "@app/utils/helpers";
import { useMyRecentJobs } from "@app/utils/query";
import { PhoneOs } from "@app/components/Icons";
import { Job } from "@app/types";

const MyRecentJobs = () => {
  const { data, isLoading } = useMyRecentJobs();

  return (
    <Section variant="ghost">
      <Header>
        <div>Recent Jobs {isLoading && <Spinner />}</div>
        {/* <Button onClick={() => {}} size="sm" variant="dark">
          Button
        </Button> */}
      </Header>

      <div className="list">
        {data?.map(({ id, status, type, project, updatedAt, details }: Job) => (
          <Link
            key={id}
            className="list-item list--jobs-recent"
            to={`/games/${project.id}/job/${id}`}
          >
            <ShortName name={project.name} className="list-icon" />
            <div className="recentJobs-nameWrap">
              <span className="recentJobs-name">{project.name}</span>
              <span className="_o50"> {getVersionInfo(details)}</span>
              <PhoneOs platform={type} size={16} className="_ml5" />
            </div>
            <TimeAgo date={updatedAt} showIcon />
            <Status variant={STATE_COLORS[status]}>{status}</Status>
          </Link>
        ))}
      </div>
    </Section>
  );
};

export { MyRecentJobs };
